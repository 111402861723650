import React from "react";
import { withBackground } from "./utils";
import Background from "../../components/Background";
import classNames from "classnames";
import { TextContent, ObjectContent } from "./BaseModules";
import get from "lodash/get";
import styles from "./TextObjectModule.module.scss";

class TextObjectModule extends React.Component {
  render() {
    const { module, documents, backgroundStyles } = this.props;
    const layout = get(module, "layout", "text-object");

    return (
      <div className={styles.textObjectModuleContainer}>
        {backgroundStyles.image && <Background {...backgroundStyles} />}
        <div className="container">
          <div
            className={classNames("row align-items-center", {
              "flex-row-reverse": layout === "object-text"
            })}
          >
            <div className="col-12 col-md-6">
              <TextContent textConfig={module.text} />
            </div>
            <div className="col-12 col-md-6">
              <ObjectContent
                documents={documents}
                objectConfig={module.object}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (TextObjectModule = withBackground(TextObjectModule));
