import React from "react";
import get from "lodash/get";
import find from "lodash/find";

export function withBackground(WrappedComponent) {
  class Wrapped extends React.Component {
    render() {
      const { module, documents } = this.props;
      const background = get(module, "background");
      let backgroundStyles;
      if (background) {
        const backgroundObjectId = get(background, "object.id");
        const backgroundObject = find(
          documents,
          item => item.document_id === backgroundObjectId
        );
        backgroundStyles = {};
        backgroundStyles.backgroundColor = get(module, "background.color");
        backgroundStyles.backgroundOverlay = get(
          module,
          "background.object.overlay"
        );
        backgroundStyles.bbox = get(module, "background.object.bbox", []);
        let backgroundImage = get(backgroundObject, "attachment");
        backgroundStyles.image = !backgroundImage
          ? ""
          : backgroundStyles.bbox.length
          ? backgroundImage
          : get(backgroundObject, "data.resolutions.medium.url", "");
      }

      return (
        <WrappedComponent backgroundStyles={backgroundStyles} {...this.props} />
      );
    }
  }
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  Wrapped.displayName = `withBackground(${displayName})`;
  return Wrapped;
}

export function withDocument(WrappedComponent, documentIdProp = "id") {
  class Wrapped extends React.Component {
    render() {
      const { documents } = this.props;

      const objectId = get(this.props, documentIdProp);
      const document = objectId
        ? find(documents, item => item.document_id === objectId)
        : undefined;
      return <WrappedComponent document={document} {...this.props} />;
    }
  }
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  Wrapped.displayName = `withBackground(${displayName})`;
  return Wrapped;
}
