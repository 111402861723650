import React from "react";
import ChapterModule from "./ChapterModules";
import get from "lodash/get";
import { Waypoint } from "react-waypoint";
import { localize } from "../../localize";
import classNames from "classnames";
import styles from "./Chapter.module.scss";

class Chapter extends React.Component {
  state = {
    enter: false
  };
  onEnter = () => {
    this.setState({ enter: true });
  };
  onLeave = () => {
    this.setState({ enter: false });
  };

  render() {
    const { chapter, index, t, mobile } = this.props;
    const { enter } = this.state;
    const modules = get(chapter, "contents.modules", []);
    return (
      <div ref={this.props.innerRef} className={styles.chapter}>
        <div className="container mb-3 mb-md-5">
          <div
            className={classNames(`row ${styles["opacity-0"]}`, {
              [styles["opacity-1"]]: enter
            })}
          >
            <div className="col-12 col-md-2">
              <h6>{`${t("chapter")} ${index}.`}</h6>
            </div>
            <div className="col-12 col-md-10">
              <h1 className="hyphens text-break">{chapter.data.title}</h1>
            </div>
          </div>
        </div>
        <Waypoint onEnter={this.onEnter} onLeave={this.onLeave} />
        {modules.map((module, i) => (
          <ChapterModule
            key={i}
            module={module}
            documents={chapter.documents}
            mobile={mobile}
          />
        ))}
      </div>
    );
  }
}

export default localize()(Chapter);
