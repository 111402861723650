import { createSelector } from "reselect";
import {
  range,
  groupBy,
  //get,
  sortBy,
  countBy,
  uniqueId,
  last,
  flatten,
  uniq
} from "lodash";
import { getSelectedLangCode } from "./lang";
import { extent } from "d3-array";
import { scaleTime } from "d3-scale";
import { translateDoc } from "./common";
import moment from "moment";
export const WIDTH_WITH_EVENTS = 300;
export const WIDTH_NO_EVENTS = 100;
export const WIDTH_WITH_EVENTS_MOBILE = 200;
export const WIDTH_NO_EVENTS_MOBILE = 40;

export const TOP_SCALE_PADDING = 50;
export const MOBILE_SCALE_PADDING = 50;
const DAY_DURATION = 86400000;

export const getRawEvents = createSelector(
  state => state.events.ids,
  state => state.events.data,
  getSelectedLangCode,
  (ids, data, langCode) =>
    ids === null ? null : ids.map(id => translateDoc(data[id], langCode))
);

export const getEvents = createSelector(
  getRawEvents,
  events =>
    events === null
      ? null
      : events.map(event => ({
          ...event,
          startDate:
            event.data.start_date === null
              ? null
              : new Date(event.data.start_date),
          endDate:
            event.data.end_date === null ? null : new Date(event.data.end_date)
        }))
);

export const getEventsCateogryCounts = createSelector(
  getEvents,
  events => {
    if (events === null) {
      return null;
    }
    return countBy(events, "data.category");
  }
);

export const getFilteredEvents = createSelector(
  getEvents,
  state => state.timeline.categories,
  state => state.timeline.milestone,
  (events, categories, milestone) =>
    events === null
      ? null
      : events.filter(
          event =>
            categories.indexOf(event.data.category) !== -1 &&
            !!event.data.key_event === milestone
        )
);

// FIXME: Move in another magic place...
export const EVENT_NO_IMAGE_HEIGHT = 100;
//const EVENT_WIDTH = 150;

// const getImageEventHeight = (event, width) => {
//   const snapshot = get(event, "documents[0].data.resolutions.low.url");
//   if (snapshot) {
//     const thumbnailHeight = get(
//       event,
//       "documents[0].data.resolutions.low.height",
//       0
//     );
//     const thumbnailWidth = get(
//       event,
//       "documents[0].data.resolutions.low.width",
//       0
//     );
//     return (thumbnailHeight * width) / thumbnailWidth;
//   }
//   return 0;
// };

// computes displaements on events
export const getAnnotatedEvents = createSelector(
  getEvents,
  events => {
    if (events == null) {
      return null;
    }
    const byCategory = groupBy(events, item => item.data.category);
    const withDisplacement = Object.values(byCategory).map(categoryEvents => {
      let sortedEvents = sortBy(categoryEvents, "startDate");

      const setDisplacements = (event, prevEvent) => {
        if (
          event.startDate.getTime() - prevEvent.endDate.getTime() <
          DAY_DURATION * 200
        ) {
          if (!prevEvent.displacementIndex) {
            prevEvent.displacementIndex = 0;
            prevEvent.displacementId = uniqueId();
          }
          event.displacementIndex = prevEvent.displacementIndex + 1;
          event.displacementId = prevEvent.displacementId;
        }
      };

      sortedEvents.forEach((event, i) => {
        if (i > 2) {
          const prevEvent = sortedEvents[i - 3];
          setDisplacements(event, prevEvent);
        }
        if (i > 1) {
          const prevEvent = sortedEvents[i - 2];
          setDisplacements(event, prevEvent);
        }
        if (i > 0) {
          const prevEvent = sortedEvents[i - 1];
          setDisplacements(event, prevEvent);
        }
      });
      return sortedEvents;
    });
    return flatten(withDisplacement).map(x => ({
      ...x,
      displacementIndex: x.displacementIndex || 0
    }));
  }
);

export const getRawPeriods = createSelector(
  state => state.periods.ids,
  state => state.periods.data,
  getSelectedLangCode,
  (ids, data, langCode) =>
    ids === null ? null : ids.map(id => translateDoc(data[id], langCode))
);

export const getPeriods = createSelector(
  getRawPeriods,
  periods =>
    periods === null
      ? null
      : periods
          .map(period => ({
            ...period,
            startDate:
              period.data.start_date === null
                ? null
                : new Date(period.data.start_date),
            endDate:
              period.data.end_date === null
                ? null
                : new Date(period.data.end_date)
          }))
          .filter(
            p =>
              p.startDate !== null &&
              p.endDate !== null &&
              !isNaN(p.startDate.getFullYear()) &&
              !isNaN(p.endDate.getFullYear())
          )
          .sort((a, b) => a.startDate - b.endDate)
);

export const getEventsExtent = createSelector(
  getEvents,
  events => {
    if (events === null) {
      return null;
    }
    const eventsExtent = extent(events, e => e.startDate);

    return eventsExtent;

    // const minYear = Math.floor(eventsExtent[0].getFullYear() / 10) * 10;
    // const maxYear = Math.ceil(eventsExtent[1].getFullYear() / 10) * 10;

    // console.log(1, eventsExtent)
    // return [moment({year: minYear}).toDate(), moment({year: maxYear}).toDate()]
  }
);

export const getPeriodsExtent = createSelector(
  getPeriods,
  periods => {
    if (periods === null) {
      return null;
    }
    return [periods[0].startDate, periods[periods.length - 1].endDate];
  }
);

export const getTimelineCurrentDate = createSelector(
  getEventsExtent,
  state => state.timeline.currentDate,
  (extent, date) => {
    if (extent === null) {
      return null;
    }
    if (date === null) {
      // return new Date(1924,0,1)
      return extent[0];
    }
    return new Date(date);
  }
);

export const getTimelineYearsWithEvents = createSelector(
  getEventsExtent,
  getAnnotatedEvents,
  (extent, events) => {
    if (extent === null) {
      return null;
    }

    const yearsRange = range(
      extent[0].getFullYear(),
      extent[1].getFullYear() + 1
    );
    const eventsByYear = groupBy(events, e => e.startDate.getFullYear());

    return yearsRange.map(year => ({
      year,
      date: new Date(year, 0, 1),
      hasEvents: !!eventsByYear[year],
      events: eventsByYear[year] || []
    }));
  }
);

export const getTimelineDesktopScale = createSelector(
  getEventsExtent,
  getTimelineYearsWithEvents,
  (extent, years) => {
    if (extent === null || years === null) {
      return null;
    }
    const scaleRange = [TOP_SCALE_PADDING];
    //const maxYear = Math.round((extent[1].getFullYear() + 1) / 10) * 10;

    let domain = (years || []).map(year => year.date);
    years.forEach((year, i) => {
      if (i > 0) {
        const delta = years[i - 1].hasEvents
          ? WIDTH_WITH_EVENTS
          : WIDTH_NO_EVENTS;
        scaleRange.push(delta + scaleRange[i - 1]);
      }
    });
    domain.push(moment({ years: last(years).year + 1 }).toDate());
    const lastRange = last(scaleRange);
    const delta = last(years).hasEvents ? WIDTH_WITH_EVENTS : WIDTH_NO_EVENTS;
    scaleRange.push(delta + lastRange);

    // // #TODO: check this
    // range(last(years).year + 1, maxYear).forEach(y => {
    //   domain.push(moment({years: y+1}).toDate())
    //   const lastRange = last(scaleRange)
    //   scaleRange.push(lastRange + WIDTH_NO_EVENTS)
    // })

    return scaleTime()
      .domain(domain)
      .range(scaleRange);
  }
);

export const getTimelineMobileScale = createSelector(
  getEventsExtent,
  getTimelineYearsWithEvents,
  (extent, years) => {
    if (extent === null || years === null) {
      return null;
    }

    const scaleRange = [];
    const domain = [];

    years.forEach((year, i) => {
      if (year.hasEvents) {
        const formattedEvents = uniq(
          year.events.map(x => moment(x.startDate).format("YYYY-MM-DD"))
        ).sort();
        const slots = formattedEvents.map(m => moment(m).toDate());

        slots.forEach(slot => {
          const eventsForSlot = year.events.filter(
            x =>
              moment(x.startDate).format("YYYY-MM-DD") ===
              moment(slot).format("YYYY-MM-DD")
          );
          const countsByCat = countBy(eventsForSlot, x => x.data.category);
          const SLOT_WIDTH =
            Math.max(...Object.values(countsByCat)) * WIDTH_WITH_EVENTS_MOBILE;

          const prevRange = last(scaleRange) || MOBILE_SCALE_PADDING;
          scaleRange.push(prevRange);
          domain.push(slot);
          scaleRange.push(SLOT_WIDTH + prevRange);
          domain.push(
            moment(slot)
              .endOf("day")
              .toDate()
          );
        });
      } else {
        const prevRange = last(scaleRange) || MOBILE_SCALE_PADDING;
        scaleRange.push(prevRange);
        domain.push(year.date);
        scaleRange.push(WIDTH_NO_EVENTS_MOBILE + prevRange);
        domain.push(
          moment(year.date)
            .endOf("year")
            .toDate()
        );
      }
    });

    return scaleTime()
      .domain(domain)
      .range(scaleRange);
  }
);

export const getTimelineCurrentPeriod = createSelector(
  getPeriods,
  getTimelineCurrentDate,
  (periods, currentDate) => {
    if (periods === null || currentDate === null) {
      return null;
    }
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      if (currentDate >= period.startDate && currentDate <= period.endDate) {
        return period;
      }
    }
    return null;
  }
);

export const getTimelinePrevPeriod = createSelector(
  getPeriods,
  getTimelineCurrentPeriod,
  (periods, period) => {
    if (period === null) {
      return null;
    }
    const index = periods.indexOf(period);
    if (index > 0) {
      return periods[index - 1];
    }
    return null;
  }
);

export const getTimelineNextPeriod = createSelector(
  getPeriods,
  getTimelineCurrentPeriod,
  (periods, period) => {
    if (period === null) {
      return null;
    }
    const index = periods.indexOf(period);
    if (index < periods.length - 1) {
      return periods[index + 1];
    }
    return null;
  }
);

export const getSelectedEvent = state => state.selectedEvent;

export const getTimelinePrevEvent = createSelector(
  getSelectedEvent,
  getAnnotatedEvents,
  (event, events) => {
    if (event === null || events === null) {
      return null;
    }
    const index = events.indexOf(event);
    if (index > 0) {
      return events[index - 1];
    }
    return null;
  }
);

export const getTimelineNextEvent = createSelector(
  getSelectedEvent,
  getAnnotatedEvents,
  (event, events) => {
    if (event === null || events === null) {
      return null;
    }
    const index = events.indexOf(event);
    if (index < events.length - 1) {
      return events[index + 1];
    }
    return null;
  }
);
