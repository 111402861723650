import React from "react";
import { connect } from "react-redux";
import {
  getTimelineCurrentDate,
  getEvents,
  getEventsExtent
} from "../../state/selectors";
import { setDateTimeline } from "../../state/actions";
import { timeYear } from "d3-time";
import { scaleTime } from "d3-scale";
import { defaultMemoize } from "reselect";
import range from "lodash/range";
import styles from "./TimelineNavigationMobile.module.scss";
import moment from "moment";
import classNames from "classnames";
import { Spring, animated } from "react-spring/renderprops";

const getDecades = defaultMemoize(extent => {
  const scale = scaleTime()
    .domain(extent)
    .nice(timeYear, 10);
  const scaleExtent = scale.domain();
  return range(scaleExtent[0].getFullYear(), scaleExtent[1].getFullYear(), 10);
});

const getCurrentDecade = defaultMemoize(currentDate => {
  return Math.floor(currentDate.getFullYear() / 10) * 10;
});

class TimelineNavigationMobile extends React.Component {
  getDateForDecade = decade => {
    const { extent } = this.props;
    const startOfDecade = moment({ years: decade }).toDate();
    return startOfDecade < extent[0]
      ? extent[0]
      : startOfDecade > extent[1]
      ? extent[1]
      : startOfDecade;
  };

  el = React.createRef();
  decade = React.createRef();
  spring = React.createRef();

  stop = () => this.spring.current.stop();

  getBestScrollLeft = (decades, currentDecade) => {
    if (!this.el.current) {
      return 0;
    }

    const decadeWidth = this.decade.current.clientWidth;
    const currentDecadeLeft = decades.indexOf(currentDecade) * decadeWidth;

    return currentDecadeLeft;
  };

  render() {
    const { extent, currentDate, setDateTimeline } = this.props;
    const decades = getDecades(extent);
    const currentDecade = getCurrentDecade(currentDate);

    const left = this.el.current ? this.el.current.scrollLeft : 0;
    const scrollLeft = this.getBestScrollLeft(decades, currentDecade);

    return (
      <Spring
        native
        reset
        from={{ left }}
        to={{ left: scrollLeft }}
        ref={this.spring}
      >
        {springProps => (
          <animated.div
            ref={this.el}
            scrollLeft={springProps.left}
            onScroll={() => {
              // this.stop()
            }}
            className={styles.container}
          >
            {decades.map((decade, i) => (
              <div
                ref={i === 0 ? this.decade : undefined}
                className={classNames(styles.decade, {
                  [`${styles.current}`]: currentDecade === decade
                })}
                key={decade}
                onClick={() => setDateTimeline(this.getDateForDecade(decade))}
              >
                {decade}
              </div>
            ))}
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default connect(
  state => ({
    events: getEvents(state),
    currentDate: getTimelineCurrentDate(state),
    extent: getEventsExtent(state)
  }),
  {
    setDateTimeline
  }
)(TimelineNavigationMobile);
