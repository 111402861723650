import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Waypoint } from "react-waypoint";
import { localize } from "../../localize";
import {
  loadTheme,
  unloadTheme,
  loadThemes,
  unloadThemes,
  setLoaderVisible
} from "../../state/actions";
import { getTheme, getThemes } from "../../state/selectors";
import ThemeCover from "../../components/ThemeCover";
import ChaptersMap from "../../components/ChaptersMapDesktop";
import ChaptersMapMobile from "../../components/ChaptersMapMobile";
import Chapter from "../../components/Chapter";
import Themes from "../Themes";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import Head from "../../components/Head";
import Loader from "../../components/Loader";
import styles from "./Theme.module.scss";

const ChapterWithRef = React.forwardRef((props, ref) => {
  return <Chapter innerRef={ref} {...props} />;
});

class Theme extends React.Component {
  state = {
    positive: true,
    listenToScroll: false,
    chapterIndex: null,
    activeChapters: []
  };

  chapterRefs = {};

  themeContainerRef = React.createRef();

  onEnter = () => {
    this.setState({ positive: true });
  };

  onLeave = () => {
    this.setState({ positive: false });
  };

  onEnterChapter = (e, slug, index) => {
    const { listenToScroll, activeChapters } = this.state;
    if (listenToScroll) {
      if (activeChapters.indexOf(slug) === -1) {
        this.setState(
          {
            activeChapters: activeChapters.concat([slug])
          },
          () => {
            const enterIndex =
              e.previousPosition === "above"
                ? 0
                : this.state.activeChapters.length - 1;
            this.setChapterToUrl({
              chapterSlug: this.state.activeChapters[enterIndex]
            });
          }
        );
      }
    }
  };

  onLeaveChapter = (e, slug, index) => {
    const { listenToScroll, activeChapters } = this.state;
    if (listenToScroll) {
      if (activeChapters.indexOf(slug) === -1) {
        return;
      }
      this.setState(
        {
          activeChapters: activeChapters.filter(x => x !== slug)
        },
        () => {
          this.setChapterToUrl({
            chapterSlug: this.state.activeChapters.length
              ? this.state.activeChapters[0]
              : ""
          });
        }
      );
    }
  };

  componentDidMount() {
    this.props.setLoaderVisible(true);

    const { match } = this.props;
    const { params } = match;
    this.props.loadTheme(params.slug);
    this.props.loadThemes();
  }

  scrollToChapter = (slug, offset, immediate) => {
    if (!this.chapterRefs[slug]) {
      return;
    }
    const node = ReactDOM.findDOMNode(this.chapterRefs[slug]);
    this.themeContainerRef.current.scrollTo({
      top: node.offsetTop - offset,
      behavior: !immediate ? "smooth" : undefined
    });
  };

  componentDidUpdate(oldProps) {
    const { match, theme } = this.props;
    const { params } = match;
    const oldParams = get(oldProps, "match.params");

    if (oldParams && params.slug !== oldParams.slug) {
      this.props.unloadTheme();
      this.setState({ activeChapters: [], chapterIndex: null });
      this.props.setLoaderVisible(true);
      this.themeContainerRef.current.scrollTo(0, 0); //to be sure
      this.props.loadTheme(params.slug);
    }

    if (!oldParams || params.chapterSlug !== oldParams.chapterSlug) {
      const { chapters = [] } = theme;
      const chapterIndex = findIndex(
        chapters,
        item => item.slug === params.chapterSlug
      );
      this.setState({ chapterIndex });
    }

    //first scroll from url
    if (this.props.theme && this.props.theme !== oldProps.theme) {
      const { chapterSlug } = params;
      // Seems like the render time of modules scrambles this behavior
      // (when mounted a chapter has a different height than when scrolling to it - problably due to module rendering, images fetching, etc)
      // but a setTimeout with 0 delay seems to fix this.
      const menuHeight = this.props.mobile ? 0 : 90;
      if (chapterSlug) {
        this.scrollToChapter(chapterSlug, menuHeight, true);
      }
      this.setState({
        listenToScroll: true
      });
    }

    if (this.props.theme !== oldProps.theme) {
      this.props.setLoaderVisible(false);
    }
  }

  componentWillUnmount() {
    this.props.unloadTheme();
    this.props.setLoaderVisible(false);
  }

  getChapterFromUrl = () => {
    const { match } = this.props;
    const { params } = match;
    const { chapterSlug } = params;
    return { chapterSlug };
  };

  setChapterToUrl = ({ chapterSlug }) => {
    const { match } = this.props;
    const { params } = match;
    const { slug } = params;
    if (chapterSlug === params.chapterSlug) {
      return;
    }
    this.props.history.push(`/themes/${slug}/${chapterSlug}`);
  };

  render() {
    const { theme, themes, mobile } = this.props;
    const { positive, chapterIndex } = this.state;
    const { chapterSlug } = this.getChapterFromUrl();
    const menuHeight = mobile ? 65 : 90;

    return (
      <React.Fragment>
        {theme && (
          <Head title={theme.data.title} description={theme.data.abstract} />
        )}

        <div className={styles.theme} ref={this.themeContainerRef}>
          <Waypoint
            onEnter={this.onEnter}
            onLeave={this.onLeave}
            topOffset={-menuHeight}
          />
          {/* theme cover */}
          {theme && themes && <ThemeCover theme={theme} themes={themes} />}

          {/* chapters map/progress */}
          {theme && themes && !mobile && (
            <ChaptersMap
              chapters={theme.chapters}
              positive={positive}
              menuHeight={menuHeight}
              themeTitle={theme.data.title}
              chapterSlug={chapterSlug}
              chapterIndex={chapterIndex}
              scrollToChapter={slug =>
                this.scrollToChapter(slug, menuHeight, false)
              }
              scrollToTop={() => {
                this.themeContainerRef.current.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              }}
            />
          )}

          {/* chapters */}
          {theme &&
            themes &&
            theme.chapters.map((chapter, i) => (
              <Waypoint
                key={chapter.id}
                ref={node => (this.chapterRefs[chapter.slug] = node)}
                onEnter={e => {
                  this.onEnterChapter(e, chapter.slug, i);
                }}
                onLeave={e => {
                  this.onLeaveChapter(e, chapter.slug, i);
                }}
                bottomOffset={"40%"}
                topOffset={mobile ? menuHeight : 0}
              >
                <ChapterWithRef
                  chapter={chapter}
                  index={i + 1}
                  mobile={mobile}
                />
              </Waypoint>
            ))}

          {/* themes index */}
          {theme && <Themes setHead={false} />}

          {/* chapters map/progress */}
          {theme && themes && mobile && (
            <ChaptersMapMobile
              chapters={theme.chapters}
              positive={positive}
              themeTitle={theme.data.title}
              chapterSlug={chapterSlug}
              chapterIndex={chapterIndex}
              scrollToChapter={slug => this.scrollToChapter(slug, 0, false)}
            />
          )}
        </div>
        {mobile && <Loader />}
      </React.Fragment>
    );
  }
}

Theme = connect(
  state => ({
    theme: getTheme(state),
    themes: getThemes(state)
  }),
  {
    loadTheme,
    unloadTheme,
    loadThemes,
    unloadThemes,
    setLoaderVisible
  }
)(localize()(Theme));

export default Theme;
