import React from "react";
import { withBackground } from "./utils";
import Background from "../../components/Background";
import classNames from "classnames";
import { ObjectContent } from "./BaseModules";
import styles from "./ObjectModule.module.scss";

class ObjectModule extends React.Component {
  render() {
    const { module, documents, backgroundStyles } = this.props;
    const goBig =
      (module.size === "big" &&
        (module.type === "image" || module.type === "pdf")) ||
      false;
    return (
      <div className={styles.objectModuleContainer}>
        {backgroundStyles.image && !goBig && (
          <Background {...backgroundStyles} />
        )}
        <div className={goBig ? "container-fluid" : "container"}>
          <div className={classNames("row align-items-center")}>
            <div className="col-12">
              <ObjectContent
                documents={documents}
                objectConfig={module}
                goBig={goBig}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (ObjectModule = withBackground(ObjectModule));
