import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  getTimelineMobileScale,
  getTimelineCurrentDate,
  getEvents,
  getSelectedEvent,
  getEventsExtent,
  getTimelineYearsWithEvents,
  WIDTH_WITH_EVENTS_MOBILE
} from "../../state/selectors";
import {
  setDateTimeline,
  selectEvent,
  clearSelectedEvent
} from "../../state/actions";
import groupBy from "lodash/groupBy";
import TimelineDetail from "./TimelineDetail";
import { localize } from "../../localize";
import styles from "./TimelineEventsMobile.module.scss";

// const MIN_EVENTS_WIDTH = 20;
// const CATEGORY_LABEL_PADDING = 30;

// # TODO: check "pointer-events: bounding-box;" cross-browser compat.
// if not enough cross-browser place a rect under content (must be measured)
// in order to get click events on all the <g> surface
class TimelineEvent extends React.PureComponent {
  render() {
    const { event, openEvent } = this.props;
    const hasImage = event.documents[0]
      ? event.documents[0].data.resolutions
        ? true
        : false
      : false;

    return (
      <div className={styles.event} onClick={() => openEvent(event)}>
        <div className={styles["event-date"]}>
          <span>{moment(event.startDate).format("DD-MM-YYYY")}</span>
          {hasImage && (
            <span className="d-flex">
              <i className="material-icons">image</i>
            </span>
          )}
          {/*event.endDate && moment(event.endDate).format("YYYY-MM-DD")*/}
        </div>
        <div className={styles["event-title"]}>{event.title}</div>
      </div>
    );
  }
}

class TimelineEventsMobile extends React.Component {
  state = {
    width: 0,
    height: 0,
    eventPanelOpen: false
  };

  measure = () => {
    const domRect = this.containerNode.getBoundingClientRect();
    const { width, height } = domRect;
    this.setState({ width, height });
  };

  componentDidMount() {
    this.measure();
    window.addEventListener("resize", this.measure);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.measure);
    this.props.clearSelectedEvent();
  }

  closeEventPanel = () => {
    this.setState({ eventPanelOpen: false });
  };

  openEventPanel = () => {
    this.setState({ eventPanelOpen: true });
  };

  // #TODO: these two methods will help if next-prev event must be implemented

  // goNext = () => {
  //   const { selectEvent, setDateTimeline, nextEvent } = this.props;
  //   setDateTimeline(nextEvent.startDate);
  //   selectEvent(nextEvent);
  // };

  // goPrev = () => {
  //   const { selectEvent, setDateTimeline, prevEvent } = this.props;
  //   setDateTimeline(prevEvent.startDate);
  //   selectEvent(prevEvent);
  // };

  setDate = null;

  handleScroll = e => {
    e.persist();
    const { scale, setDateTimeline } = this.props;
    const newDate = scale.invert(e.target.scrollLeft);
    setDateTimeline(newDate);
    this.setDate = moment(newDate).format();
  };

  componentDidUpdate(oldProps) {
    const { scale, currentDate } = this.props;
    if (
      oldProps.currentDate !== currentDate &&
      this.setDate !== moment(currentDate).format()
    ) {
      this.setDate = null;
      this.containerNode.scrollTo({
        //behavior: "smooth",
        left: scale(currentDate)
      });
    }
  }

  render() {
    const { events, scale, years, selectedEvent, selectEvent, t } = this.props;
    const { eventPanelOpen } = this.state;

    const byCat = groupBy(events, x => x.data.category);
    const byCatByStart = Object.keys(byCat).reduce((acc, cat) => {
      const data = groupBy(byCat[cat], x =>
        moment(x.startDate).format("YYYY-MM-DD")
      );
      acc[cat] = data;
      return acc;
    }, {});

    const categories = ["history of the bank", "political", "socio-economic"];

    return (
      <div className={styles["wrapper"]}>
        <div className={styles["category-bg-container"]}>
          {categories.map((category, i) => (
            <div
              className={styles[`${category.replace(/\s/g, "")}Row`]}
              key={category}
            />
          ))}
        </div>
        <div
          className={styles["container"]}
          ref={node => (this.containerNode = node)}
          onScroll={this.handleScroll}
        >
          <div className={`${styles.eventRow}`}>
            {Object.keys(byCatByStart["history of the bank"]).map(startDate => (
              <div
                key={startDate}
                className={styles.eventGroupContainer}
                style={{ left: scale(moment(startDate).toDate()) }}
              >
                {byCatByStart["history of the bank"][startDate].map(event => (
                  <div
                    key={event.id}
                    className={styles.eventContainer}
                    style={{ width: WIDTH_WITH_EVENTS_MOBILE }}
                  >
                    <TimelineEvent
                      openEvent={event => {
                        selectEvent(event);
                        this.openEventPanel();
                      }}
                      event={event}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className={`${styles.eventRow}`}>
            {Object.keys(byCatByStart["political"]).map(startDate => (
              <div
                key={startDate}
                className={styles.eventGroupContainer}
                style={{ left: scale(moment(startDate).toDate()) }}
              >
                {byCatByStart["political"][startDate].map(event => (
                  <div
                    key={event.id}
                    className={styles.eventContainer}
                    style={{ width: WIDTH_WITH_EVENTS_MOBILE }}
                  >
                    <TimelineEvent
                      openEvent={event => {
                        selectEvent(event);
                        this.openEventPanel();
                      }}
                      event={event}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className={`${styles.eventRow}`}>
            {Object.keys(byCatByStart["socio-economic"]).map(startDate => (
              <div
                key={startDate}
                className={styles.eventGroupContainer}
                style={{ left: scale(moment(startDate).toDate()) }}
              >
                {byCatByStart["socio-economic"][startDate].map(event => (
                  <div
                    key={event.id}
                    className={styles.eventContainer}
                    style={{ width: WIDTH_WITH_EVENTS_MOBILE }}
                  >
                    <TimelineEvent
                      openEvent={event => {
                        selectEvent(event);
                        this.openEventPanel();
                      }}
                      event={event}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          {/* bottom years */}
          <div className={styles.ticksContainer}>
            {years.map(year => (
              <div
                className={styles.tick}
                key={year.year}
                style={{ left: scale(year.date) }}
              >
                <div className={styles.line} />
                <div className={styles.label}>{year.year}</div>
              </div>
            ))}
          </div>

          {/* selected event panel */}
          <div className={styles.overlay}>
            <TimelineDetail
              selectedEvent={selectedEvent}
              open={eventPanelOpen}
              closeEventPanel={this.closeEventPanel}
            />
          </div>
        </div>
        {/* label category*/}
        <div className={styles["category-label-container"]}>
          {categories.map((category, i) => (
            <div className={styles["category-label"]} key={category}>
              {t(category)}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    scale: getTimelineMobileScale(state),
    events: getEvents(state),
    years: getTimelineYearsWithEvents(state),
    currentDate: getTimelineCurrentDate(state),
    extent: getEventsExtent(state),
    selectedEvent: getSelectedEvent(state)
  }),
  {
    setDateTimeline,
    clearSelectedEvent,
    selectEvent
  }
)(localize()(TimelineEventsMobile));
