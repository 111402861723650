import React from "react";
import { connect } from "react-redux";
import {
  getPlaces,
  getPlacesExtent,
  getMapTimelineCurrentDate
} from "../../state/selectors";
import { setSelectedPlace } from "../../state/actions";
import classNames from "classnames";
import { timeYear } from "d3-time";
import { scaleTime } from "d3-scale";
import { ascending } from "d3-array";
import { nest } from "d3-collection";
import { defaultMemoize } from "reselect";
import moment from "moment";
import { MAP_PLACE_TYPE } from "../../consts";
import { localize } from "../../localize";
import styles from "./MapGantt.module.scss";

// #TODO: MOVE IN PARENT COMPONENT OR TO UTILS
const getScale = defaultMemoize((extent, width, padding) => {
  const scale = scaleTime()
    .domain([
      moment(extent[0])
        .startOf("year")
        .toDate(),
      moment(extent[1])
        .endOf("year")
        .toDate()
    ])
    .range([0, width - padding])
    .nice(timeYear);
  return scale;
});

class MapGantt extends React.Component {
  state = {
    open: false,
    width: 0
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  measure = () => {
    const domRect = this.ganttRef.current.getBoundingClientRect();
    const { width } = domRect;
    this.setState({ width });
  };

  componentDidMount = () => {
    this.measure();
    window.addEventListener("resize", this.measure);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.measure);
  }

  ganttRef = React.createRef();

  render() {
    const { places, extent, t, currentDate, setSelectedPlace } = this.props;
    const { width } = this.state;
    const height = 3;

    // #TODO: get your scale here, (you should measure the available with from a ref)
    const padding = 25;
    const scale = getScale(extent, width, padding * 2);
    let bottomTicks = scale.ticks();
    bottomTicks.push(moment({ years: 2020 }).toDate());

    //#TODO remove from here
    const rightPlaces = nest()
      .key(d => d.title)
      .entries(
        places
          .filter(place => {
            return (
              moment(place.startDate, "YYYY-MM-DD").isValid() &&
              moment(place.endDate, "YYYY-MM-DD").isValid() &&
              place.coordinates.length > 0
            );
          })
          .sort((a, b) => {
            return ascending(a.startDate, b.startDate);
          })
      );

    const { open } = this.state;
    return (
      <div
        className={classNames(styles.ganttcontainer, { [styles.open]: open })}
      >
        <div className={styles.ganttheader} onClick={this.toggleOpen}>
          <div
            className={`${styles.ganttButton} d-flex align-items-center h-100`}
          >
            <i className="material-icons">
              {open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </i>{" "}
            <h6 className="ellipsis">{t("all_openings")}</h6>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center h-100`}
          >
            {MAP_PLACE_TYPE.map(type => {
              return (
                <div className={styles.legend} key={type.label}>
                  <span className={`icon-${type.icon} mr-1`} />
                  {t(type.icon)}
                </div>
              );
            })}
            {/*<div className={styles.legend}>
              <i className={`material-icons mr-1 d-flex`}>data_usage</i>
              {t("cluster")}
            </div>*/}

            <div className={styles.legendColors}>
              <div className={styles.legend}>
                <i className={`material-icons mr-1 d-flex`}>stop</i>
                {t("map_mapTooltipOpen")}
              </div>

              <div className={styles.legend}>
                <i className={`${styles.closed} d-flex material-icons mr-1`}>
                  stop
                </i>
                {t("map_mapTooltipClose")}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ganttcontent}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-3" />
              <div className="col-9 p-0" ref={this.ganttRef} />
            </div>
            {rightPlaces &&
              width &&
              rightPlaces.map((place, i) => {
                return (
                  <div
                    className={classNames("row align-items-center", {
                      [styles.rowBlack]: i % 2 === 0,
                      [styles.rowDisabled]:
                        scale(place.values[0].startDate) > scale(currentDate)
                    })}
                    key={place.key}
                  >
                    <div className="col-3 text-truncate">
                      <h6
                        className={styles.title}
                        onClick={() => {
                          const last = place.values[place.values.length - 1];
                          if (currentDate >= last.startDate) {
                            setSelectedPlace(last);
                          } else {
                            setSelectedPlace(place.values[0]);
                          }
                        }}
                      >
                        <span
                          className={`icon-${
                            place.values[0].data.place_type
                          } mr-1`}
                        />
                        {place.values[0].data.title}
                      </h6>
                    </div>
                    <div className="col-9 p-0">
                      <svg width={width} height={30}>
                        <g transform={`translate(${padding},0)`}>
                          {bottomTicks.map((tick, i) => {
                            return (
                              <g
                                key={i}
                                transform={`translate(${scale(tick)},0)`}
                              >
                                <line
                                  className={styles.tickLine}
                                  y1={0}
                                  y2={30}
                                />
                              </g>
                            );
                          })}
                          {place.values.map(v => {
                            return (
                              <React.Fragment key={v.slug}>
                                <rect
                                  className={styles.ganttLine}
                                  x={scale(v.startDate)}
                                  y={15 - height / 2}
                                  height={height}
                                  width={
                                    v.data.existing
                                      ? scale.range()[1] - scale(v.startDate)
                                      : scale(v.endDate) - scale(v.startDate)
                                  }
                                />
                                {!v.data.existing && (
                                  <circle
                                    cx={scale(v.endDate)}
                                    cy={15 - height / 2 + 2}
                                    r={4}
                                    fill={"white"}
                                    stroke={"black"}
                                    strokeWidth={2}
                                  />
                                )}
                                <line
                                  x1={scale(v.startDate)}
                                  x2={scale(v.startDate)}
                                  y1={10}
                                  y2={20}
                                  stroke={"white"}
                                  strokeWidth={2}
                                />
                              </React.Fragment>
                            );
                          })}
                          <g transform={`translate(${scale(currentDate)},0)`}>
                            <line
                              className={styles.tickCurrent}
                              y1={0}
                              y2={30}
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

MapGantt = connect(
  state => ({
    places: getPlaces(state),
    extent: getPlacesExtent(state),
    currentDate: getMapTimelineCurrentDate(state)
  }),
  { setSelectedPlace }
)(MapGantt);

export default localize()(MapGantt);
