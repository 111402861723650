import React from "react";
import { withRouter, Link, matchPath } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import Loader from "../../components/Loader";
import { localize } from "../../localize";
import { getStories } from "../../state/selectors";
import { loadStories, unloadStories } from "../../state/actions";
import { Trail, Transition } from "react-spring/renderprops";
import Cookies from "../Cookies";
import styles from "./AppContainer.module.scss";

class Bar extends React.Component {
  state = {
    mouseover: false
  };

  toggleMouseover = () => {
    this.setState({ mouseover: !this.state.mouseover });
  };
  render() {
    const { story, barOpen, location, toggleBarOpen, t, style } = this.props;
    const { mouseover } = this.state;

    const matchingPage = !!matchPath(location.pathname, {
      path: [`/${story.slug}`],
      exact: false
    });

    return (
      <React.Fragment>
        <div
          className={classNames(`${styles["menu-bar"]} ${styles[story.slug]}`, {
            [`${styles["open"]}`]: barOpen[story.slug],
            [`${styles["pages"]}`]: matchingPage
          })}
          style={style}
        >
          <div className={styles["menu-bar-title"]}>
            <div
              className={classNames(styles["title-container"], {
                [styles["over"]]: mouseover
              })}
            >
              <p className="text-uppercase">{story.data.title}</p>
            </div>
            <div className={styles["expand-container"]}>
              <i
                onMouseOver={this.toggleMouseover}
                onMouseOut={this.toggleMouseover}
                className="material-icons"
                onClick={toggleBarOpen}
              >
                add
              </i>
            </div>
          </div>
          <div className={styles["menu-bar-desc"]}>
            <div className={styles["desc-container"]}>
              <p>{story.data.abstract}</p>
            </div>
            <div className={styles["desc-link-container"]}>
              <Link
                className={styles["desc-link"]}
                to={`/${story.slug}`}
                onClick={toggleBarOpen}
              >
                <span>{t("home_explore")}</span>
                <span className="d-flex">
                  <i className="material-icons">arrow_forward</i>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles["menu-page-content"], {
            [`${styles["open"]}`]: matchingPage
          })}
        >
          <Loader />
        </div>
      </React.Fragment>
    );
  }
}

class AppContainer extends React.PureComponent {
  state = {
    paddingRight: 0,
    paddingLeft: 0,
    barOpen: {}
  };

  componentDidMount() {
    this.props.loadStories();
  }

  componentWillUnmount() {
    this.props.unloadStories();
  }

  toggleBarOpen = name => () => {
    const { barOpen } = this.state;
    const newValue = !barOpen[name];
    this.setState({ barOpen: { [name]: newValue } });
  };

  render() {
    const { stories, location, t } = this.props;
    const { barOpen } = this.state;
    return (
      <React.Fragment>
        <div className={`${styles["AppContainer"]} h-100 text-white`}>
          <div
            className={classNames(styles["content"], {
              [`${styles["p-timeline"]}`]: !!matchPath(location.pathname, {
                path: ["/timeline"],
                exact: false
              }),
              [`${styles["p-map"]}`]: !!matchPath(location.pathname, {
                path: ["/map"],
                exact: false
              }),
              [`${styles["p-themes"]}`]: !!matchPath(location.pathname, {
                path: ["/themes"],
                exact: false
              })
            })}
          >
            {this.props.children}
          </div>

          <div
            className={classNames(styles["menu-container"], {
              [`${styles["pages"]}`]: !matchPath(location.pathname, {
                path: ["/", "/about"],
                exact: true
              })
            })}
          >
            <Transition
              items={[stories]}
              from={{ opacity: 0 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
            >
              {stories =>
                stories &&
                (props => (
                  <div className={`${styles["menu-bar-home"]} `} style={props}>
                    <div className={styles["menu-bar-title-home"]}>
                      <div className={styles["title-container"]}>
                        <p className="text-uppercase">{t("home")}</p>
                      </div>
                      <div className={styles["expand-container-home"]}>
                        <Link to={"/"}>
                          <i className="material-icons d-flex">arrow_back</i>
                        </Link>
                        <div className={styles["about-button"]}>
                          <Link to={"/about"}>
                            <i className="material-icons d-flex">info</i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </Transition>
            {stories && (
              <Trail
                items={stories}
                keys={item => item.slug}
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
              >
                {item => props => (
                  <Bar
                    story={item}
                    toggleBarOpen={this.toggleBarOpen(item.slug)}
                    location={location}
                    barOpen={barOpen}
                    style={props}
                    t={t}
                  />
                )}
              </Trail>
            )}
          </div>
        </div>
        <Cookies />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  stories: getStories(state)
});

export default localize()(
  connect(
    mapStateToProps,
    {
      loadStories,
      unloadStories
    }
  )(withRouter(AppContainer))
);
