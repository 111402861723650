import React from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { scaleLinear } from "d3-scale";
import { sum } from "d3-array";
import { localize } from "../../localize";
import { Tooltip } from "reactstrap";
import styles from "./ChaptersMap.module.scss";

class TooltipWrapper extends React.Component {
  state = {
    tooltipOpen: false
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  render() {
    const { tooltipOpen } = this.state;
    const { target, content } = this.props;
    return (
      <Tooltip
        innerClassName={styles.tooltip}
        placement="top"
        isOpen={tooltipOpen}
        target={target}
        toggle={this.toggle}
        offset={"0, 8"}
      >
        {content}
      </Tooltip>
    );
  }
}

class ChaptersMap extends React.Component {
  state = {
    previewChapterSlug: null
  };

  setPreviewChapterSlug = slug => {
    this.setState({ previewChapterSlug: slug });
  };

  render() {
    const {
      chapters,
      positive,
      menuHeight,
      t,
      themeTitle,
      chapterSlug,
      scrollToChapter,
      scrollToTop
    } = this.props;

    const { previewChapterSlug } = this.state;

    const totModules = sum(
      chapters.map(c => {
        return c.contents.modules.length;
      })
    );
    const scale = scaleLinear()
      .range([0, 100])
      .domain([0, totModules]);

    return (
      <div
        className={classNames(styles.chaptersMap, {
          [styles.negative]: !positive
        })}
        style={{ height: menuHeight }}
      >
        <div className="container-fluid h-100">
          <div
            className={classNames("row h-100", {
              "align-items-center": positive,
              "align-items-stretch": !positive
            })}
          >
            <div className="col-2">
              <div className={styles.chapters} onClick={scrollToTop}>
                {positive ? t("chapters") : themeTitle}
              </div>
            </div>
            <div className="col-10">
              <div className="d-flex">
                {chapters &&
                  chapters.map((chapter, i) => {
                    return (
                      <div
                        key={chapter.id}
                        className={styles["points-container"]}
                        style={{
                          width: `${scale(chapter.contents.modules.length)}%`
                        }}
                      >
                        <div
                          className={classNames(styles["points-modules-line"], {
                            [styles.active]: chapterSlug === chapter.slug
                          })}
                        >
                          <div
                            className={styles.points}
                            id={chapter.slug}
                            onMouseOver={() =>
                              this.setPreviewChapterSlug(chapter.slug)
                            }
                            onMouseOut={() => this.setPreviewChapterSlug(null)}
                            onClick={() => scrollToChapter(chapter.slug)}
                          >
                            {positive && (
                              <TooltipWrapper
                                target={chapter.slug}
                                content={chapter.data.title}
                              />
                            )}
                          </div>
                          {i + 1 === chapters.length && (
                            <div className={styles["end-chapter"]} />
                          )}
                        </div>
                        {!positive && (
                          <div
                            className={classNames(styles["chapter-title"], {
                              [styles["opacity-1"]]:
                                chapterSlug === chapter.slug ||
                                previewChapterSlug === chapter.slug
                            })}
                          >
                            {`${i + 1}. ${chapter.data.title}`}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(localize()(ChaptersMap));
