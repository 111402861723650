export const GET_STORIES = "GET_STORIES";
export const GET_STORIES_SUCCESS = "GET_STORIES_SUCCESS";
export const GET_STORIES_LOADING = "GET_STORIES_LOADING";
export const GET_STORIES_FAILURE = "GET_STORIES_FAILURE";
export const GET_STORIES_UNLOAD = "GET_STORIES_UNLOAD";

export const loadStories = () => ({
  type: GET_STORIES
});

export const unloadStories = () => ({
  type: GET_STORIES_UNLOAD
});
