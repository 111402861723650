import React from "react";
import classNames from "classnames";
import { localize } from "../../localize";
import get from "lodash/get";
import moment from "moment";
import { accessToken, mapBoxStyle } from "./consts";
import { connect } from "react-redux";
import { getPlacesExtent } from "../../state/selectors";
import { timeYear } from "d3-time";
import { scaleTime } from "d3-scale";
import { defaultMemoize } from "reselect";
import OpenImageButton from "../../components/OpenImageButton";
import styles from "./MapDetail.module.scss";

// #TODO: MOVE IN PARENT COMPONENT OR TO UTILS
const getScale = defaultMemoize((extent, width, padding) => {
  const scale = scaleTime()
    .domain([
      moment(extent[0])
        .startOf("year")
        .toDate(),
      moment(extent[1])
        .endOf("year")
        .toDate()
    ])
    .range([0, width - padding * 2])
    .nice(timeYear);
  return scale;
});

class MapDetail extends React.PureComponent {
  state = {
    width: 0
  };

  miniMap = React.createRef();

  measure = () => {
    const domRect = this.miniMap.current.getBoundingClientRect();
    const { width } = domRect;
    this.setState({ width });
  };

  componentDidMount = () => {
    this.measure();
  };

  measureTexAnchor = (type, start, end, width) => {
    if (end - start < 30 && end < width / 2) {
      if (type === "start") {
        return "middle";
      } else {
        return "start";
      }
    } else if (end - start < 30 && end >= width / 2) {
      if (type === "start") {
        return "end";
      } else {
        return "middle";
      }
    } else {
      return "middle";
    }
  };

  render() {
    const { selectedPlace, closePlacePanel, t, open, extent } = this.props;
    const { width } = this.state;
    const height = 32;
    const padding = 15;
    const margin = 15;
    const scale = getScale(extent, width, padding + margin);

    return (
      <div
        className={classNames(styles.eventPanel, {
          [`${styles.open}`]: open
        })}
        ref={this.miniMap}
      >
        {selectedPlace && (
          <div className={styles.selectedPlaceContent}>
            {/* category and close control */}
            <div
              className={`${
                styles["detail-top"]
              } d-flex justify-content-between align-items-center`}
            >
              <h6 className={styles["detail-category"]}>
                {t(selectedPlace.data.place_type)}
              </h6>
              <i className="material-icons" onClick={closePlacePanel}>
                close
              </i>
            </div>
            <div className="d-flex d-md-none">
              <div className={styles.region}>
                {width && (
                  <img
                    src={`https://api.mapbox.com/styles/v1/${mapBoxStyle}/static/pin-s-circle+fff(${
                      selectedPlace.coordinates[0]
                    },${selectedPlace.coordinates[1]})/${
                      selectedPlace.coordinates[0]
                    },${selectedPlace.coordinates[1]},${15},0,0/${width -
                      padding *
                        2}x150@2x?access_token=${accessToken}&attribution=false&logo=false`}
                    alt={"map"}
                  />
                )}
              </div>
            </div>
            <h5 className={styles["detail-title"]}>
              {selectedPlace.data.title}
            </h5>

            {/* dates */}
            {width &&
              moment(selectedPlace.endDate).isValid() &&
              moment(selectedPlace.startDate).isValid() && (
                <div className={styles.miniTimeline}>
                  <svg width={width - padding * 2} height={height}>
                    <g transform={`translate(${margin},0)`}>
                      <line
                        className={styles.bgTimeline}
                        x1={0}
                        x2={width - padding * 2 - margin * 2}
                        y1={height / 2}
                        y2={height / 2}
                      />
                      <g>
                        <line
                          className={styles.overTimeline}
                          x1={scale(selectedPlace.startDate)}
                          x2={scale(selectedPlace.endDate)}
                          y1={height / 2}
                          y2={height / 2}
                        />
                        <line
                          x1={scale(selectedPlace.startDate)}
                          x2={scale(selectedPlace.startDate)}
                          y1={height / 2 - 4}
                          y2={height / 2 + 4}
                          className={styles.overTimeline}
                        />
                        {!selectedPlace.data.existing && (
                          <React.Fragment>
                            <circle
                              cx={scale(selectedPlace.endDate) - 2}
                              cy={height / 2}
                              r={4}
                              fill={"white"}
                              stroke={"black"}
                              strokeWidth={2}
                            />
                            <text
                              x={
                                scale(selectedPlace.endDate) -
                                  scale(selectedPlace.startDate) <
                                15
                                  ? scale(selectedPlace.endDate) + 13
                                  : scale(selectedPlace.endDate)
                              }
                              y={8}
                              textAnchor={this.measureTexAnchor(
                                "end",
                                scale(selectedPlace.startDate),
                                scale(selectedPlace.endDate),
                                scale.range()[1]
                              )}
                            >
                              {moment(selectedPlace.endDate).format("YYYY")}
                            </text>
                          </React.Fragment>
                        )}
                        <text
                          x={scale(selectedPlace.startDate)}
                          y={8}
                          textAnchor={this.measureTexAnchor(
                            "start",
                            scale(selectedPlace.startDate),
                            scale(selectedPlace.endDate),
                            scale.range()[1]
                          )}
                        >
                          {moment(selectedPlace.startDate).format("YYYY")}
                        </text>

                        <text
                          className={styles.domain}
                          x={scale.range()[0]}
                          y={32}
                          textAnchor={"middle"}
                        >
                          {moment(scale.domain()[0]).format("YYYY")}
                        </text>
                        <text
                          className={styles.domain}
                          x={scale.range()[1]}
                          y={32}
                          textAnchor={"middle"}
                        >
                          {moment(scale.domain()[1]).format("YYYY")}
                        </text>
                      </g>
                    </g>
                  </svg>

                  {/*
                  <span className={styles["detail-date"]}>
                  {moment(selectedPlace.startDate).format("YYYY-MM-DD")}
                  </span>
                  */}
                  {/*moment(selectedPlace.endDate).format("YYYY-MM-DD") !==
                    moment(selectedPlace.startDate).format("YYYY-MM-DD") && (
                    <span className={styles["detail-date"]}>
                    {selectedPlace.endDate &&
                    moment(selectedPlace.endDate).format("YYYY-MM-DD")}
                    </span>
                    )*/}
                </div>
              )}

            {/* image if any */}
            {get(selectedPlace, "documents[0].data.resolutions.medium.url") && (
              <div className={styles["selected-image-container"]}>
                <img
                  className={styles.selectedPlaceImage}
                  alt={selectedPlace.title}
                  src={selectedPlace.documents[0].data.resolutions.medium.url}
                />
                <OpenImageButton link={selectedPlace.documents[0].attachment} />
                <div className={styles["selected-image-info"]}>
                  {selectedPlace.documents[0].data.title && (
                    <p className={styles["selected-image-title"]}>
                      {selectedPlace.documents[0].data.title}
                    </p>
                  )}
                  {selectedPlace.documents[0].data.provenance && (
                    <p
                      className={`${
                        styles["selected-image-provenance"]
                      } text-break`}
                    >
                      {selectedPlace.documents[0].data.provenance}
                    </p>
                  )}
                </div>
              </div>
            )}
            <p>{selectedPlace.data.address}</p>
          </div>
        )}
      </div>
    );
  }
}

MapDetail = connect(
  state => ({
    extent: getPlacesExtent(state)
  }),
  {}
)(MapDetail);

export default localize()(MapDetail);
