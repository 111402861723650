import React from "react";
import { connect } from "react-redux";
import { setDateTimelineMap } from "../../state/actions";
import {
  getPlaces,
  getMapTimelineCurrentDate,
  getPlacesExtent
} from "../../state/selectors";
import { timeYear } from "d3-time";
import { scaleTime } from "d3-scale";
import { defaultMemoize } from "reselect";
import range from "lodash/range";
import moment from "moment";
import { localize } from "../../localize";
import { MAP_PLACE_TYPE } from "../../consts";
import classNames from "classnames";
import { Spring, animated } from "react-spring/renderprops";
import styles from "./MapNavigationMobile.module.scss";

const getYears = defaultMemoize(extent => {
  const scale = scaleTime()
    .domain(extent)
    .nice(timeYear);
  const scaleExtent = scale.domain();
  return range(scaleExtent[0].getFullYear(), scaleExtent[1].getFullYear());
});

const getCurrentYear = defaultMemoize(currentDate => {
  return currentDate.getFullYear();
});

class MapNavigationMobile extends React.Component {
  getDateForYear = year => {
    const { extent } = this.props;
    const startOfYear = moment({ years: year }).toDate();
    return startOfYear < extent[0]
      ? extent[0]
      : startOfYear > extent[1]
      ? extent[1]
      : startOfYear;
  };

  el = React.createRef();
  year = React.createRef();
  spring = React.createRef();

  stop = () => this.spring.current.stop();

  getBestScrollLeft = (years, currentYear) => {
    // this condition prevents scroll on first render. see componentDidMount for fix
    if (!this.el.current) {
      return 0;
    }
    const yearWidth = this.year.current.clientWidth;
    const currentYearLeft = years.indexOf(currentYear) * yearWidth;
    return currentYearLeft;
  };

  componentDidMount() {
    // forcing re-render in order to obtain the first scroll
    const { currentDate, setDateTimelineMap } = this.props;
    setDateTimelineMap(currentDate);
  }

  render() {
    const { extent, currentDate, setDateTimelineMap, t } = this.props;
    const years = getYears(extent);
    const currentYear = getCurrentYear(currentDate);

    const left = this.el.current ? this.el.current.scrollLeft : 0;
    const scrollLeft = this.getBestScrollLeft(years, currentYear);

    return (
      <div>
        <div className={styles.legend}>
          {MAP_PLACE_TYPE.map(type => {
            return (
              <div key={type.label} className={styles.type}>
                <span className={`icon-${type.icon} mr-1`} />
                <span className="mt-auto">{t(type.icon)}</span>
              </div>
            );
          })}
          {/*         <div className={styles.type}>
            <i className={`material-icons mr-1 d-flex`}>data_usage</i>
            <span className="mt-auto">{t("cluster")}</span>
          </div>*/}
          <div className={styles.type}>
            <i className={`material-icons mr-1 d-flex`}>stop</i>
            <span className="mt-auto">{t("map_mapTooltipOpen")}</span>
          </div>

          <div className={styles.type}>
            <i className={`${styles.closed} d-flex material-icons mr-1`}>
              stop
            </i>
            <span className="mt-auto">{t("map_mapTooltipClose")}</span>
          </div>
        </div>
        <Spring
          native
          reset
          from={{ left }}
          to={{ left: scrollLeft }}
          ref={this.spring}
        >
          {springProps => (
            <animated.div
              ref={this.el}
              scrollLeft={springProps.left}
              onScroll={() => {
                // this.stop()
              }}
              className={styles.container}
            >
              {years.map((year, i) => (
                <div
                  ref={i === 0 ? this.year : undefined}
                  className={classNames(styles.year, {
                    [`${styles.current}`]: currentYear === year
                  })}
                  key={year}
                  onClick={() => setDateTimelineMap(this.getDateForYear(year))}
                >
                  {year}
                </div>
              ))}
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }
}

MapNavigationMobile = connect(
  state => ({
    places: getPlaces(state),
    currentDate: getMapTimelineCurrentDate(state),
    currentDateRaw: state.map.currentDate,
    extent: getPlacesExtent(state)
  }),
  {
    setDateTimelineMap
  }
)(localize()(MapNavigationMobile));

export default MapNavigationMobile;
