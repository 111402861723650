import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import isArray from 'lodash/isArray'
import qs from 'query-string'


export const normalizeCollection = (collection, byKey = 'id') => ({
  ids: map(collection, byKey),
  data: keyBy(collection, byKey),
})

export const arrayze = a => (isArray(a) ? a : [a])

export const getQsSafeYear = location => {
  const yearQs = qs.parse(location.search)['year']
  return Math.abs(parseInt(yearQs, 10))
}

export const makeUrlWithYear = (location, year) => {
  const qsAsObject = qs.parse(location.search)
  const newQs = qs.stringify({
    ...qsAsObject,
    year
  })
  return `${location.pathname}?${newQs}`
}

export const makeUrlWithYearAndFilters = (location, year, categories = [], milestone) => {
  const qsAsObject = qs.parse(location.search)
  const newQs = qs.stringify({
    ...qsAsObject,
    year,
    milestone: milestone ? 1 : 0,
    categories: categories.join(','),
  }, {
    encode: false,
  })
  return `${location.pathname}?${newQs}`
}
