import React from "react";
import { connect } from "react-redux";
import { DraggableCore } from "react-draggable";
import {
  getTimelineCurrentDate,
  getEvents,
  getEventsExtent
} from "../../state/selectors";
import moment from "moment";
import { setDateTimeline } from "../../state/actions";
import { Spring } from "react-spring/renderprops";
import { timeYear } from "d3-time";
import { scaleTime } from "d3-scale";
import { defaultMemoize } from "reselect";
import { localize } from "../../localize";
import styles from "./TimelineNavigation.module.scss";

const MIN_EVENTS_WIDTH = 2;
const DISPLACEMENTS = {
  "history of the bank": 0,
  political: 25,
  "socio-economic": 50
};

const getScale = defaultMemoize((extent, width, padding) => {
  const scale = scaleTime()
    .domain([
      moment(extent[0])
        .startOf("year")
        .toDate(),
      moment(extent[1])
        .endOf("year")
        .toDate()
    ])
    .range([padding, width - padding])
    .nice(timeYear);
  return scale;
});

class TimelineNavigation extends React.Component {
  state = {
    width: 0,
    height: 0
  };

  measure = () => {
    const domRect = this.svgNode.getBoundingClientRect();
    const { width, height } = domRect;
    this.setState({ width, height });
  };

  componentDidMount() {
    this.measure();
    window.addEventListener("resize", this.measure);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.measure);
  }

  onDrag = (e, data) => {
    const { extent, currentDate, setDateTimeline } = this.props;
    const { width } = this.state;
    const scale = getScale(extent, width, 50);
    const x = scale(currentDate);
    const newX = x + data.deltaX;
    const newDate = scale.invert(newX);

    if (newDate < extent[0] || newDate > extent[1]) {
      return;
    }
    setDateTimeline(newDate);
  };

  handleClick = e => {
    e.persist();
    const { extent, setDateTimeline } = this.props;
    const { width } = this.state;
    const rect = this.svgNode.getBoundingClientRect();
    const x = e.clientX - rect.x;
    const scale = getScale(extent, width, 50);
    const newDate = scale.invert(x);
    if (newDate < extent[0] || newDate > extent[1]) {
      return;
    }
    setDateTimeline(newDate);
  };

  render() {
    const { events, extent, currentDate, t } = this.props;
    const { width } = this.state;
    const scale = getScale(extent, width, 50);
    const x = scale(currentDate);

    let bottomTicks = scale.ticks();
    bottomTicks.push(moment({ years: 2019 }).toDate());

    return (
      <div className={styles["container"]}>
        <div className={styles.labels}>
          <div className={styles.label}>{t("history of the bank")}</div>
          <div className={styles.label}>{t("political")}</div>
          <div className={styles.label}>{t("socio-economic")}</div>
          {/*         <div className={styles.legend}>
            <div className="flex-grow-0 flex-shrink-0">{t("n_events")}</div>
            <div className={`${styles.gradient} flex-grow-1 flex-shrink-1`} />
            <div className="flex-grow-0 flex-shrink-0">{t("more")}</div>
          </div>*/}
        </div>
        <Spring to={{ x: x }} config={{ tension: 0, friction: 4 }}>
          {springProps => (
            <DraggableCore handle=".handle" onDrag={this.onDrag}>
              <svg
                className={styles.svg}
                width={width}
                ref={node => (this.svgNode = node)}
              >
                <g onClick={this.handleClick}>
                  {/* category rects */}
                  <rect
                    className={styles.historyRow}
                    width={width}
                    height={25}
                  />
                  <rect
                    y={DISPLACEMENTS["political"]}
                    className={styles.politicalRow}
                    width={width}
                    height={25}
                  />
                  <rect
                    y={DISPLACEMENTS["socio-economic"]}
                    className={styles.socioEconomicRow}
                    width={width}
                    height={25}
                  />

                  {/* ticks */}
                  <g transform={`translate(0, 100)`}>
                    {bottomTicks.map((tick, i) => {
                      const transformedTick =
                        tick.getFullYear() === 1920
                          ? moment({ years: tick.getFullYear() - 1 }).toDate()
                          : tick;
                      return (
                        <g key={i}>
                          <text
                            className={styles.tick}
                            x={scale(transformedTick)}
                            dy={-8}
                          >
                            {transformedTick.getFullYear()}
                          </text>
                        </g>
                      );
                    })}
                  </g>

                  {/* events */}
                  <g>
                    {events.map(evt => (
                      <rect
                        key={evt.id}
                        className={styles.event}
                        x={scale(evt.startDate)}
                        y={DISPLACEMENTS[evt.data.category]}
                        width={
                          Math.abs(scale(evt.endDate) - scale(evt.startDate)) <
                          MIN_EVENTS_WIDTH
                            ? MIN_EVENTS_WIDTH
                            : Math.abs(
                                scale(evt.endDate) - scale(evt.startDate)
                              )
                        }
                        height={25}
                      />
                    ))}
                  </g>
                </g>
                {/* cursor */}
                <g
                  transform={`translate(${springProps.x - 50},0)`}
                  className="handle pointer"
                >
                  <rect
                    y={0}
                    x={50}
                    width={50}
                    height={75}
                    className={styles["cursor"]}
                  />
                  <rect
                    x={48}
                    width={3}
                    height={100}
                    className={styles["cursor-bar"]}
                  />
                </g>
              </svg>
            </DraggableCore>
          )}
        </Spring>
      </div>
    );
  }
}

export default connect(
  state => ({
    events: getEvents(state),
    currentDate: getTimelineCurrentDate(state),
    extent: getEventsExtent(state)
  }),
  {
    setDateTimeline
  }
)(localize()(TimelineNavigation));
