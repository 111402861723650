import React from "react";
import { connect } from "react-redux";
import { localize } from "../../localize";
import { getLoaderVisible } from "../../state/selectors";
import classNames from "classnames";
import styles from "./Loader.module.scss";
// import fadeTransition from "./Fade.module.scss";
// import "./Loader.css";

class Loader extends React.PureComponent {
  state = {
    showLoader: false
  };

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ showLoader: true }), 750);
  }

  componentDidUpdate(oldProps) {
    if (this.props.loaderVisible !== oldProps.loaderVisible) {
      clearTimeout(this.timer);
      this.setState({ showLoader: false });
      if (this.props.loaderVisible) {
        this.timer = setTimeout(() => this.setState({ showLoader: true }), 750);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { loaderVisible } = this.props;
    const { showLoader } = this.state;
    return (
      <div
        className={classNames(styles.loader, {
          [styles.active]: showLoader && loaderVisible
        })}
      >
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  loaderVisible: getLoaderVisible(state)
}))(localize()(Loader));
