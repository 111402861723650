import { createSelector } from "reselect";
import { getSelectedLangCode } from "./lang";
import { translateDoc } from "./common";

export const getStories = createSelector(
  state => state.stories.data,
  getSelectedLangCode,
  (stories, langCode) => {
    if (stories === null) {
      return null;
    }
    return translateDoc(stories, langCode);
  }
);
