import React from "react";
import classNames from "classnames";
import { localize } from "../../localize";
import { Spring, animated } from "react-spring/renderprops";
import styles from "./ChaptersMapMobile.module.scss";

class ChaptersMapMobile extends React.Component {
  stop = () => this.spring.current.stop();
  el = React.createRef();
  // year = React.createRef();
  spring = React.createRef();
  render() {
    const {
      chapters,
      positive,
      // menuHeight,
      // t,
      // themeTitle,
      chapterSlug,
      chapterIndex,
      scrollToChapter
    } = this.props;
    const chapterWidth = 200;
    const scrollLeft = chapterIndex * chapterWidth;
    const left = this.el.current ? this.el.current.scrollLeft : 0;
    return (
      <Spring
        native
        reset
        from={{ left }}
        to={{ left: scrollLeft }}
        ref={this.spring}
      >
        {springProps => (
          <animated.div
            ref={this.el}
            scrollLeft={springProps.left}
            onWheel={() => {
              this.stop();
            }}
            className={classNames(styles.container, {
              [styles.negative]: !positive
            })}
          >
            {chapters.map((chapter, i) => (
              <div
                className={classNames(styles.chapter, {
                  [`${styles.active}`]: chapterSlug === chapter.slug
                })}
                key={chapter.slug}
                style={{
                  minWidth: chapterWidth,
                  maxWidth: chapterWidth
                }}
                onClick={() => {
                  scrollToChapter(chapter.slug);
                }}
              >
                <div className="mr-1">
                  {(i + 1).toString().padStart(2, "0")}
                  {"."}
                </div>
                <div>{chapter.data.title}</div>
              </div>
            ))}
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default localize()(ChaptersMapMobile);
