import React from "react";
import { localize } from "../../localize";
import styles from "./ThemeCover.module.scss";

const ThemeCover = ({ theme, themes, t, innerRef }) => (
  <div
    ref={innerRef}
    className={styles.themeCover}
    style={{
      backgroundImage: `url(${theme.covers[0].data.resolutions.medium.url})`
    }}
  >
    <div className="container my-auto">
      <div className="row">
        <div className="col-12">
          <h5>
            {t("dossier n.")}
            {`${(themes.map(d => d.slug).indexOf(theme.slug) + 1)
              .toString()
              .padStart("2", 0)}`}
          </h5>
          <h1 className="hyphens text-break">{theme.data.title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <p>{theme.data.abstract}</p>
        </div>
      </div>
    </div>
  </div>
);

export default localize()(ThemeCover);
