import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { localize } from "../../localize";
import {
  loadEvents,
  unloadEvents,
  clearSelectedEvent,
  setLoaderVisible
} from "../../state/actions";
import { getEvents, getStories } from "../../state/selectors";
import Head from "../../components/Head";
import Loader from "../../components/Loader";
import TimelineNavigation from "./TimelineNavigation";
import TimelineEvents from "./TimelineEvents";
import TimelineNavigationMobile from "./TimelineNavigationMobile";
import TimelineEventsMobile from "./TimelineEventsMobile";

// import Spinner from "../../components/Spinner";

class Timeline extends PureComponent {
  componentDidMount() {
    this.props.setLoaderVisible(true);
    this.props.loadEvents({ detailed: true });
  }

  componentDidUpdate(oldProps) {
    if (this.props.events && !oldProps.events) {
      this.props.setLoaderVisible(false);
    }
  }

  componentWillUnmount() {
    this.props.unloadEvents();
    this.props.clearSelectedEvent();
    this.props.setLoaderVisible(false);
  }

  render() {
    const { events, mobile, stories } = this.props;
    const story = stories ? stories.filter(d => d.slug === "timeline") : [];
    const title = story.length ? story[0].data.title : "";
    const description = story.length ? story[0].data.abstract : "";

    return (
      <React.Fragment>
        {stories && <Head title={title} description={description} />}
        <div className="Timeline h-100 d-flex flex-column position-relative">
          {!mobile && (
            <React.Fragment>
              {events && <TimelineEvents />}
              {events && <TimelineNavigation />}
            </React.Fragment>
          )}

          {mobile && (
            <React.Fragment>
              {events && <TimelineEventsMobile />}
              {events && <TimelineNavigationMobile />}
              <Loader />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  events: getEvents(state),
  stories: getStories(state)
});
export default connect(
  mapStateToProps,
  {
    loadEvents,
    unloadEvents,
    clearSelectedEvent,
    setLoaderVisible
  }
)(localize()(Timeline));
