import { fork, put, call, select, takeEvery } from "redux-saga/effects";
import { setLanguage } from "redux-i18n";
import { takeLatestAndCancel } from "./effects/take";
import makeDocumentsListSaga from "./hos/documents";
import makeFacetsSaga from "./hos/facets";
import {
  GET_CHAPTERS,
  GET_CHAPTERS_UNLOAD,
  GET_STORIES,
  GET_STORIES_UNLOAD,
  GET_EVENTS,
  GET_PERIODS,
  GET_PLACES,
  GET_HOME_DOCS,
  GET_COLLECTION_DOCS,
  GET_COLLECTION_FACETS,
  GET_STORY,
  GET_STORY_SUCCESS,
  GET_STORY_LOADING,
  GET_STORY_FAILURE,
  GET_STORY_UNLOAD,
  SET_SELECTED_LANG,
  SEARCH_SUGGESTION,
  CLEAR_SEARCH_SUGGESTION,
  GET_DOCUMENT,
  GET_DOCUMENT_UNLOAD,
  GET_TIME_SERIES,
  GET_TIME_SERIES_UNLOAD,
  GET_RASTER_LAYERS,
  GET_THEMES,
  GET_THEMES_UNLOAD,
  GET_THEME,
  GET_THEME_UNLOAD,
} from "../actions";
import { getSelectedLang } from "../selectors";
import * as api from "../../api";

function* handleGetStory({ payload }) {
  yield put({ type: GET_STORY_LOADING });
  try {
    const story = yield call(api.getStory, payload);
    yield put({ type: GET_STORY_SUCCESS, payload: story });
  } catch (error) {
    yield put({ type: GET_STORY_FAILURE, error });
  }
}

function* handleGetDocument({ payload }) {
  yield put({ type: `${GET_DOCUMENT}_LOADING` });
  try {
    const story = yield call(api.getDocument, payload);
    yield put({ type: `${GET_DOCUMENT}_SUCCESS`, payload: story });
  } catch (error) {
    yield put({ type: `${GET_DOCUMENT}_FAILURE`, error });
  }
}

function* handleGetTimeSeries({ payload }) {
  yield put({ type: `${GET_TIME_SERIES}_LOADING` });
  try {
    const series = yield call(api.getTimeSeries, payload);
    yield put({ type: `${GET_TIME_SERIES}_SUCCESS`, payload: series });
  } catch (error) {
    yield put({ type: `${GET_TIME_SERIES}_FAILURE`, error });
  }
}

function* handleSearchSuggestion({ payload }) {
  yield put({ type: `${SEARCH_SUGGESTION}_LOADING` });
  try {
    const results = yield call(api.searchSuggestion, payload);
    yield put({ type: `${SEARCH_SUGGESTION}_SUCCESS`, payload: results });
  } catch (error) {
    yield put({ type: `${SEARCH_SUGGESTION}_FAILURE`, error });
  }
}

function* handleGetThemes() {
  yield put({ type: `${GET_THEMES}_LOADING` });
  try {
    const themes = yield call(api.getThemes);
    yield put({ type: `${GET_THEMES}_SUCCESS`, payload: themes });
  } catch (error) {
    yield put({ type: `${GET_THEMES}_FAILURE`, error });
  }
}

function* handleGetTheme({ payload }) {
  yield put({ type: `${GET_THEME}_LOADING` });
  try {
    const themes = yield call(api.getTheme, payload);
    yield put({ type: `${GET_THEME}_SUCCESS`, payload: themes });
  } catch (error) {
    yield put({ type: `${GET_THEME}_FAILURE`, error });
  }
}

function* handleGetChapters() {
  yield put({ type: `${GET_CHAPTERS}_LOADING` });
  try {
    const chapters = yield call(api.getChapters);
    yield put({ type: `${GET_CHAPTERS}_SUCCESS`, payload: chapters });
  } catch (error) {
    yield put({ type: `${GET_CHAPTERS}_FAILURE`, error });
  }
}

function* handleGetStories() {
  yield put({ type: `${GET_STORIES}_LOADING` });
  try {
    const stories = yield call(api.getStories);
    yield put({ type: `${GET_STORIES}_SUCCESS`, payload: stories });
  } catch (error) {
    yield put({ type: `${GET_STORIES}_FAILURE`, error });
  }
}

function* syncLangWi18n() {
  const lang = yield select(getSelectedLang);
  yield put(setLanguage(lang.param));
}

export default function* rootSaga() {
  yield takeEvery(SET_SELECTED_LANG, syncLangWi18n);
  yield fork(
    takeLatestAndCancel,
    GET_TIME_SERIES,
    GET_TIME_SERIES_UNLOAD,
    handleGetTimeSeries
  );
  yield fork(
    takeLatestAndCancel,
    GET_THEMES,
    GET_THEMES_UNLOAD,
    handleGetThemes
  );
  yield fork(
    takeLatestAndCancel,
    GET_THEME,
    GET_THEME_UNLOAD,
    handleGetTheme
  );
  yield fork(
    takeLatestAndCancel,
    GET_CHAPTERS,
    GET_CHAPTERS_UNLOAD,
    handleGetChapters
  );
  yield fork(
    takeLatestAndCancel,
    GET_STORIES,
    GET_STORIES_UNLOAD,
    handleGetStories
  );
  yield fork(takeLatestAndCancel, GET_STORY, GET_STORY_UNLOAD, handleGetStory);
  yield fork(
    takeLatestAndCancel,
    GET_DOCUMENT,
    GET_DOCUMENT_UNLOAD,
    handleGetDocument
  );
  yield fork(
    makeDocumentsListSaga(
      GET_HOME_DOCS,
      api.getHomeDocuments,
      state => state.homeDocs,
      50 // <--- Count of home images!
    )
  );
  yield fork(
    makeDocumentsListSaga(
      GET_COLLECTION_DOCS,
      api.getCollectionDocuments,
      state => state.collectionDocs
    )
  );
  yield fork(makeFacetsSaga(GET_COLLECTION_FACETS, api.getCollectionDocuments));
  yield fork(
    makeDocumentsListSaga(GET_EVENTS, api.getEvents, state => state.events)
  );
  yield fork(
    makeDocumentsListSaga(GET_PERIODS, api.getPeriods, state => state.periods)
  );
  yield fork(
    makeDocumentsListSaga(GET_PLACES, api.getPlaces, state => state.periods)
  );
  yield fork(
    takeLatestAndCancel,
    SEARCH_SUGGESTION,
    CLEAR_SEARCH_SUGGESTION,
    handleSearchSuggestion
  );
  yield fork(
    makeDocumentsListSaga(
      GET_RASTER_LAYERS,
      api.getRasterLayers,
      state => state.rasterLayers
    )
  );
}
