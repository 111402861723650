import React from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { getStory } from "../../state/selectors";
import { loadStory, unloadStory, setLoaderVisible } from "../../state/actions";
import { localize } from "../../localize";
import { Link } from "react-router-dom";
import { Transition } from "react-spring/renderprops";
import Loader from "../../components/Loader";
import Head from "../../components/Head";
import styles from "./About.module.scss";
import bglLogo from "../../style/logos/bgl.png";
import c2dhLogo from "../../style/logos/c2dh.png";
import uniLogo from "../../style/logos/uni.png";

class About extends React.Component {
  componentDidMount() {
    this.props.setLoaderVisible(true);
    this.props.loadStory("about");
  }

  componentDidUpdate(oldProps) {
    if (this.props.story && !oldProps.story) {
      this.props.setLoaderVisible(false);
    }
  }

  componentWillUnmount() {
    this.props.unloadStory();
  }

  render() {
    const { story, t } = this.props;
    return (
      <React.Fragment>
        {story && (
          <Head title={story.data.title} description={story.data.abstract} />
        )}

        <div className={styles["background"]}>
          <Loader />
          <div className={`${styles["grid"]} container`}>
            <div className="row h-100">
              <div className={`${styles["border-first"]} col-1 col-md-2`} />
              <div className={`${styles["border"]} col-5 col-md-3`} />
              <div className={`${styles["border"]} col-5 col-md-2`} />
              <div
                className={`${styles["border"]} d-none d-md-block col-md-3`}
              />
              <div
                className={`${styles["border"]} d-none d-md-block col-md-2`}
              />
            </div>
          </div>

          <Transition
            items={story}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
          >
            {story =>
              story &&
              (props => (
                <div className={`${styles["content"]}`} style={props}>
                  <div className="container">
                    <div className={`row pt-md-5`}>
                      <div className="col-1 offset-1 offset-md-0 col-md-2 pl-0 pr-0 pl-md-3 pr-md-3">
                        <Link
                          className={`${
                            styles["read-more"]
                          } text-white d-flex align-items-center`}
                          to="/"
                        >
                          <span className={`${styles["back"]} d-flex`}>
                            <i className="material-icons">arrow_back</i>
                          </span>
                          <span className="ml-2 d-none d-md-block">
                            {t("home")}
                          </span>
                        </Link>
                      </div>
                      <div className="col-10 offset-1 offset-md-0 col-md-8 pl-0 pr-0">
                        <h1 className={styles["title"]}>{story.data.title}</h1>
                      </div>
                    </div>
                    <div className="row align-items-center text-center py-3 py-md-5">
                      <div className="col col-md-3 offset-md-2">
                        <a
                          href="https://uni.lu/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={uniLogo}
                            height="60"
                            width="auto"
                            alt="logo university"
                          />
                        </a>
                      </div>
                      <div className="col col-md-2">
                        <a
                          href="https://www.c2dh.uni.lu/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={c2dhLogo}
                            height="50"
                            width="auto"
                            alt="logo c2dh"
                          />
                        </a>
                      </div>
                      <div className="col col-md-3">
                        <a
                          href="https://www.bgl.lu/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={bglLogo}
                            height="50"
                            width="auto"
                            alt="logo bgl"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-10 offset-1 col-md-8 offset-md-2 mt-3 mt-md-5 pl-0 pr-0">
                        <ReactMarkdown
                          className={styles["description"]}
                          source={story.data.abstract}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </Transition>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  story: getStory(state)
});

export default localize()(
  connect(
    mapStateToProps,
    {
      loadStory,
      unloadStory,
      setLoaderVisible
    }
  )(About)
);
