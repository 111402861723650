import React from "react";
import { withBackground } from "./utils";
import Background from "../../components/Background";
import classNames from "classnames";
import { TextContent, GalleryContent } from "./BaseModules";
import get from "lodash/get";
import styles from "./TextGalleryModule.module.scss";

class TextGalleryModule extends React.Component {
  state = { width: 0 };

  galleryContainer = React.createRef();

  componentDidMount() {
    const styles = window.getComputedStyle(this.galleryContainer.current);
    const padding =
      parseInt(styles.paddingLeft) + parseInt(styles.paddingRight);
    this.setState({
      width: this.galleryContainer.current.clientWidth - padding
    });
  }

  render() {
    const { module, documents, backgroundStyles, mobile } = this.props;
    const { width } = this.state;
    const layout = get(module, "layout", "text-object");
    const settings = {
      className: "slider",
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      fade: true
    };
    return (
      <div className={styles.textGalleryModuleContainer}>
        {backgroundStyles.image && <Background {...backgroundStyles} />}
        <div className="container">
          <div
            className={classNames("row align-items-center", {
              "flex-row-reverse": layout === "gallery-text"
            })}
          >
            <div className="col-12 col-md-6">
              <TextContent textConfig={module.text} />
            </div>
            <div className="col-12 col-md-6" ref={this.galleryContainer}>
              <GalleryContent
                galleryConfig={module.gallery}
                documents={documents}
                settings={settings}
                mobile={mobile}
                width={width}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (TextGalleryModule = withBackground(TextGalleryModule));
