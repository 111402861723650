export const accessToken =
  "pk.eyJ1IjoiYmdsLWNlbnRlbmFpcmUtdGVjaCIsImEiOiJjanZkam9iNjMxaDlhNGRubW51Yzl5bWc3In0.zg0KZbLLPEFpfgcC-DiKXg";
export const mapBoxStyle = "bgl-centenaire-tech/cjvdyrrwf1lyv1fpq4ps2pmj5";

export const LUXEMBOURG_CENTER = [6.1008033, 49.8148384];
export const ITALY_CENTER = [9.1964284, 45.4810602];
export const HONG_KONG_CENTER = [114.158, 22.284];
export const MEXICO_CENTER = [-98.7670845, 19.055402];
export const GERMANY_CENTER = [8.6718568, 50.1068642];
export const SWITZERLAND_CENTER = [8.5387, 47.374];
