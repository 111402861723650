import React from "react";
import { localize } from "../../localize";
import CookieBanner from "react-cookie-banner";

const message =
  "BGL BNP Paribas utilise des cookies sur ce site. En poursuivant la navigation sur notre site, vous acceptez l'utilisation de ces cookies.";

const styles = {
  banner: {
    height: "auto",
    background: "rgba(0, 4, 27, 0.75)",
    fontWeight: "normal",
    position: "fixed",
    zIndex: 10000,
    left: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    padding: "15px"
  },
  button: {
    border: "1px solid white",
    width: 66,
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    opacity: 1,
    marginLeft: "auto",
    position: "relative",
    borderRadius: "0",
    top: "unset",
    marginTop: "unset",
    right: "unset"
  },
  message: {
    textAlign: "left",
    color: "white",
    fontWeight: "normal",
    lineHeight: 1.1,
    marginRight: 15
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold"
  }
};

const Cookies = ({ mobile, t }) => {
  return (
    <CookieBanner
      styles={styles}
      message={message}
      link={
        <a href="https://www.bgl.lu/content/dam/bank/common/documents/cookies/politique-cookies.pdf">
          Politique de Cookie
        </a>
      }
      buttonMessage={t("accept")}
      dismissOnScroll={true}
      dismissOnClick={true}
    />
  );
};

Cookies.defaultProps = {
  mobile: false
};

export default localize()(Cookies);
