import {
  GET_STORIES_SUCCESS,
  GET_STORIES_LOADING,
  GET_STORIES_FAILURE,
  GET_STORIES_UNLOAD
} from "../actions";

const defaultState = {
  data: null,
  loading: false,
  error: null
};

export default (prevState = defaultState, { type, payload, error }) => {
  switch (type) {
    case GET_STORIES_SUCCESS:
      return {
        ...prevState,
        loading: false,
        data: payload
      };
    case GET_STORIES_LOADING:
      return {
        ...prevState,
        loading: true,
        error: null
      };
    case GET_STORIES_FAILURE:
      return {
        ...prevState,
        error,
        loading: false
      };
    case GET_STORIES_UNLOAD:
      return defaultState;
    default:
      return prevState;
  }
};
