import React from "react";
import styles from "./OpenImageButton.module.scss";

const OpenImageButton = ({ link }) => {
  return (
    <div className={styles.openImageButton} openimagebutton="true">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <i className="material-icons">open_in_new</i>
      </a>
    </div>
  );
};

export default OpenImageButton;
