import React from "react";
import { localize } from "../../localize";
import styles from "./NotFound.module.scss";
import { Link } from "react-router-dom";

class NotFound extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className={styles.container}>
        <h1 className={styles.question}>?</h1>
        <h3>{t("not_found")}</h3>
        <Link to="/" className={styles.link}>
          <span>{t("home")}</span>
          <span className="d-flex">
            <i className="material-icons">arrow_forward</i>
          </span>
        </Link>
      </div>
    );
  }
}

export default localize()(NotFound);
