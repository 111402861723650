export default {
  de: {},
  fr: {
    // side menu
    menu_home: "Page d’accueil",
    menu_map: "Carte",
    menu_timeline: "Ligne du temps",
    menu_about: "À propos",
    menu_collection: "Collection",
    menu_themes: "Dossiers",
    back: "retour",
    menu_terms_of_use: "Conditions d’utilisation",
    home_readmore: "Lire plus",
    home_explore: "explorer",
    home: "accueil",
    political: "histoire politique",
    "socio-economic": "histoire socio-économique",
    "history of the bank": "histoire de la banque",
    menu_contact_us: "Contactez-Nous",
    office: "bureau",
    branch: "agence",
    headquarter: "siège",
    all_openings: "toutes les ouvertures",
    luxembourg: "luxembourg",
    italy: "italie",
    germany: "allemagne",
    switzerland: "suisse",
    mexico: "mexique",
    "hong kong": "hong kong",
    map_mapTooltipOpen: "ouvert",
    map_mapTooltipClose: "fermé",
    cluster: "cluster",
    "dossier n.": "dossier n.",
    theme: "thematique",
    chapters: "chapitres",
    chapter: "chapitre",
    n_events: "n° des evénements",
    more: "plus",
    close: "fermer",
    accept: "accepter",
    not_found: "Erreur 404. Page non trouvée",
    //check
    map_officeClose: "Bureaux fermés",
    map_officeOpen: "Bureaux ouverts",
    map_legendDetail: "Bureaux",

    map_mapTooltipFrom: "de",
    map_mapTooltipTo: "à",
    map_opacity: "Opacité de la carte historique",
    // timeline page
    timeline_periode: "periode",
    timeline_next_event: "Evénement suivant",
    timeline_prev_event: "Evénement précédent",
    milestones_only: "Dates clé",
    all_events: "Tous les événements",
    // timeline page - legend
    event: "Evénement",
    "legislation and events": "Législation et evénement",
    infrastructure: "Infrastructure",
    service: "Services",
    cooperation: "Coopération internationale",
    staff: "Personnel",
    transport: "Transport",
    building: "Bâtiment",
    technology: "Technologie",
    partners: "Collaborateurs",
    open: "ouvrir",
    related_documents: "documents liés",
    other: "autre",
    book: "livre",
    map: "carte",
    advertising: "publicité",
    video: "vidéo",
    object: "objet",
    law: "loi",
    image: "image",
    title: "titre",
    date: "date",
    description: "description",
    reset: "reset",
    soon_online: "bientôt en ligne",
    speaker: "interviewé",
    unknown: "inconnu"
  }
};
