import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "./state";
import { Provider } from "react-redux";
import I18n from "redux-i18n";
import translations from "./translations";
import Media from "react-media";
import AppContainer from "./components/AppContainer";
import AppContainerMobile from "./components/AppContainerMobile";
import Home from "./pages/Home";
import About from "./pages/About";
import Timeline from "./pages/Timeline";
import NotFound from "./pages/NotFound";
import Map from "./pages/Map";
import Themes from "./pages/Themes";
import Theme from "./pages/Theme";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <I18n translations={translations} initialLang="fr">
          <Router>
            <Media query="(max-width: 768px)">
              {matches =>
                matches ? (
                  <AppContainerMobile>
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/about" exact component={About} />
                      <Route
                        path="/timeline"
                        exact
                        render={props => <Timeline mobile {...props} />}
                      />
                      <Route
                        path="/map"
                        exact
                        render={props => <Map mobile {...props} />}
                      />
                      <Route path="/themes" component={Themes} exact />
                      <Route
                        path="/themes/:slug/:chapterSlug?"
                        render={props => <Theme mobile {...props} />}
                      />
                      <Route path="/" component={NotFound} />
                    </Switch>
                  </AppContainerMobile>
                ) : (
                  <AppContainer>
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/about" exact component={About} />
                      <Route path="/timeline" exact component={Timeline} />
                      <Route path="/map" exact component={Map} />
                      <Route path="/themes" component={Themes} exact />
                      <Route
                        path="/themes/:slug/:chapterSlug?"
                        component={Theme}
                      />
                      <Route path="/" component={NotFound} />
                    </Switch>
                  </AppContainer>
                )
              }
            </Media>
          </Router>
        </I18n>
      </Provider>
    );
  }
}

export default App;
