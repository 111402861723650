import React from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Transition } from "react-spring/renderprops";
import { getStory } from "../../state/selectors";
import { loadStory, unloadStory } from "../../state/actions";
import { localize } from "../../localize";
import { Link } from "react-router-dom";
import Head from "../../components/Head";
import styles from "./Home.module.scss";
import bglLogo from "../../style/logos/bgl.png";
import c2dhLogo from "../../style/logos/c2dh.png";
import uniLogo from "../../style/logos/uni.png";

class Home extends React.Component {
  state = {
    random: 1
  };
  componentDidMount() {
    this.setState({ random: Math.floor(Math.random() * 5) + 1 });
    this.props.loadStory("home");
  }

  componentWillUnmount() {
    this.props.unloadStory();
  }

  render() {
    const { story, t } = this.props;
    const { random } = this.state;
    return (
      <React.Fragment>
        {story && (
          <Head title={story.data.subtitle} description={story.data.abstract} />
        )}
        <Transition
          items={story}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {story =>
            story &&
            (props => (
              <div
                className={`${styles["background"]} ${
                  styles["image_" + random]
                }`}
                style={props}
              >
                <div className={`${styles["grid"]} container h-100`}>
                  <div className="row h-100">
                    <div
                      className={`${styles["border-first"]} col-1 col-md-2`}
                    />
                    <div className={`${styles["border"]} col-5 col-md-3`} />
                    <div className={`${styles["border"]} col-5 col-md-2`} />
                    <div
                      className={`${
                        styles["border"]
                      } d-none d-md-block col-md-3`}
                    />
                    <div
                      className={`${
                        styles["border"]
                      } d-none d-md-block col-md-2`}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    styles["content"]
                  } container h-100 d-flex flex-column`}
                >
                  <div className="row align-items-center my-md-auto">
                    <div className="col-10 offset-1 col-md-8 offset-md-2 pl-0 pr-0">
                      <h1 className={`${styles["title"]} text-break`}>
                        {story.data.subtitle && story.data.subtitle.substr(3)}
                      </h1>
                    </div>
                    <div className="offset-1 col-10 col-md-8 offset-md-2 pl-0 pr-0">
                      <ReactMarkdown
                        className={styles["description"]}
                        source={story.data.abstract}
                      />
                    </div>
                    <div className="offset-1 col-10 col-md-3 offset-md-2 pl-0 pr-0">
                      <Link
                        className={`${
                          styles["read-more"]
                        } text-white d-flex justify-content-between align-items-center`}
                        to="/about"
                      >
                        <span>{t("home_readmore")}</span>
                        <span className="d-flex">
                          <i className="material-icons">arrow_forward</i>
                        </span>
                      </Link>
                    </div>
                    <div className="d-none col-md-12 d-md-block mt-5">
                      <div className="row d-flex align-items-center text-center">
                        <div className="col-md-3 offset-md-2">
                          <a
                            href="https://uni.lu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={uniLogo}
                              height="60"
                              width="auto"
                              className={styles["logo-img"]}
                              alt="logo university"
                            />
                          </a>
                        </div>
                        <div className="col-md-2">
                          <a
                            href="https://www.c2dh.uni.lu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={c2dhLogo}
                              height="50"
                              width="auto"
                              alt="logo c2dh"
                              className={styles["logo-img"]}
                            />
                          </a>
                        </div>
                        <div className="col-md-3">
                          <a
                            href="https://www.bgl.lu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={bglLogo}
                              height="50"
                              width="auto"
                              alt="logo bgl"
                              className={styles["logo-img"]}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </Transition>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  story: getStory(state)
});

export default localize()(
  connect(
    mapStateToProps,
    {
      loadStory,
      unloadStory
    }
  )(Home)
);
