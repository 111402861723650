import React from "react";
import ReactMapboxGl from "react-mapbox-gl";
import { accessToken } from "../../pages/Map/consts";
import get from "lodash/get";
import find from "lodash/find";
import { LngLatBounds } from "mapbox-gl";
import { mapPlace } from "../../state/selectors";
import { MapContent } from "./BaseModules";
import styles from "./MapModule.module.scss";

const MapGL = ReactMapboxGl({
  accessToken,
  maxZoom: 17,
  minZoom: 6,
  scrollZoom: false,
  logoPosition: "top-left"
});

class MapModule extends React.Component {
  render() {
    const { module, documents, mobile } = this.props;

    const objectIds = get(module, "objects", []).map(x => x.id);
    const places = objectIds
      .map(item => find(documents, x => x.document_id === item))
      .filter(x => x !== undefined)
      .map(x => mapPlace(x));

    const bounds = new LngLatBounds();

    places.forEach(function(place) {
      if (place.coordinates) {
        bounds.extend(place.coordinates);
      }
    });

    const fitBounds = bounds.toArray();

    return (
      <div className={styles.mapModuleContainer}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-md-0">
              <MapContent
                module={module}
                places={places}
                Map={MapGL}
                fitBounds={fitBounds}
                mobile={mobile}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapModule;
