import React from "react";
import { connect } from "react-redux";
import { localize } from "../../localize";
import { loadThemes, unloadThemes } from "../../state/actions";
import { getThemes, getStories } from "../../state/selectors";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Head from "../../components/Head";
import styles from "./Themes.module.scss";

class Themes extends React.Component {
  state = {
    backgroundImage: null
  };

  setBackgroundImage = id => {
    this.setState({ backgroundImage: id });
  };

  getHeadTitle = stories => {
    const story = stories.filter(d => d.slug === "themes");
    const title = story[0].data.title;
    return title;
  };

  getHeadDesc = stories => {
    const story = stories.filter(d => d.slug === "themes");
    const description = story[0].data.abstract;
    return description;
  };

  componentDidMount() {
    this.props.loadThemes();
  }

  componentWillUnmount() {
    this.props.unloadThemes();
  }

  render() {
    const { themes, t, stories, setHead = true } = this.props;
    const { backgroundImage } = this.state;

    return (
      <React.Fragment>
        {stories && setHead && (
          <Head
            title={this.getHeadTitle(stories)}
            description={this.getHeadDesc(stories)}
          />
        )}
        <div className={styles["container-themes"]}>
          {themes &&
            themes.map((theme, i) => (
              <div
                key={theme.id}
                className={classnames(styles["container-background"], {
                  [styles["container-background-opacity"]]:
                    backgroundImage === theme.id
                })}
              >
                <img
                  alt={theme.covers[0].data.title}
                  src={theme.covers[0].data.resolutions.medium.url}
                />
              </div>
            ))}
          {themes && (
            <div className={styles["container-content"]}>
              <div className="container my-0 my-md-auto">
                <div className={`${styles["header"]} row`}>
                  <div className={`${styles["col-grid"]} col-2`}>
                    <span className="d-none d-md-block">{t("dossier n.")}</span>
                    <span className="d-block d-md-none">{"n."}</span>
                  </div>
                  <div
                    className={`${
                      styles["col-grid"]
                    } d-none d-md-block col-md-2`}
                  />
                  <div className={`${styles["col-grid"]} col-8 col-md-4`}>
                    <span>{t("theme")}</span>
                  </div>
                  <div
                    className={`${
                      styles["col-grid"]
                    } d-none d-md-block col-md-2`}
                  />
                  <div className={`${styles["col-grid"]} col-2`} />
                </div>
                {themes.map((theme, i) => (
                  <div
                    className={`${styles["line"]} row`}
                    key={theme.id}
                    onMouseOver={() => {
                      this.setBackgroundImage(theme.id);
                    }}
                    onMouseOut={() => {
                      this.setBackgroundImage(null);
                    }}
                  >
                    <div className={`${styles["col-grid"]} col-2`}>
                      {(i + 1).toString().padStart(2, "0")}
                    </div>
                    <div
                      className={`${
                        styles["col-grid"]
                      } d-none d-md-block col-md-2`}
                    />
                    <div className={`${styles["col-grid"]} col-8 col-md-4`}>
                      <Link to={`/themes/${theme.slug}`}>
                        <h3>{theme.data.title}</h3>
                      </Link>
                    </div>
                    <div
                      className={`${
                        styles["col-grid"]
                      } d-none d-md-block col-md-2`}
                    />
                    <div className={`${styles["col-grid"]} col-2`}>
                      <Link to={`/themes/${theme.slug}`}>
                        <i className="material-icons">arrow_forward</i>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

Themes = connect(
  state => ({
    themes: getThemes(state),
    stories: getStories(state)
  }),
  {
    loadThemes,
    unloadThemes
  }
)(localize()(Themes));

export default Themes;
