import React from 'react'

export default ({ module }) => {
  return (
    <div className="alert alert-info">
      This module has not yet implemented ({module.module}):
      <pre>{JSON.stringify(module)}</pre>
    </div>
  );
};
