import React from "react";
import { connect } from "react-redux";
import { localize } from "../../localize";
import {
  setSelectedPlace,
  clearSelectedPlace,
  setOverPlace,
  clearOverPlace,
  setDateTimelineMap
} from "../../state/actions";
import {
  getPlacesInDate,
  getPlaceTypesCount,
  getMapOverPlace,
  getMapSelectedPlace,
  getMapTimelineCurrentDate,
  getPlacesExtent
} from "../../state/selectors";
import ReactMapboxGl, {
  Popup,
  Marker,
  Cluster,
  ZoomControl
} from "react-mapbox-gl";
import { scaleLinear } from "d3-scale";
import MapDetail from "./MapDetail";
import MapTooltip from "./MapTooltip";
import MapMarkerDonut from "./MapMarkerDonut";
import classNames from "classnames";
import get from "lodash/get";
import RegionSelector from "./RegionSelector";
import styles from "./MapDesktop.module.scss";
import {
  LUXEMBOURG_CENTER,
  ITALY_CENTER,
  HONG_KONG_CENTER,
  MEXICO_CENTER,
  SWITZERLAND_CENTER,
  GERMANY_CENTER,
  accessToken,
  mapBoxStyle
} from "./consts";

const Map = ReactMapboxGl({
  accessToken,
  maxZoom: 17,
  logoPosition: "top-left"
});

const circleScale = scaleLinear()
  .range([30, 100])
  .domain([1, 150]);

class MapDesktop extends React.Component {
  state = {
    center: LUXEMBOURG_CENTER,
    zoom: [9],
    placePanelOpen: false
  };

  closePlacePanel = () => {
    this.setState({ placePanelOpen: false });
  };

  openPlacePanel = () => {
    this.setState({ placePanelOpen: true });
  };

  componentWillUpdate(nextProps) {
    if (nextProps.selectedPlace !== this.props.selectedPlace) {
      this.openPlacePanel();
      this.handleSetCenter(nextProps.selectedPlace.coordinates, [17]);
    }
  }
  componentDidMount() {}

  componentWillUnmount() {}

  clusterMarker = (coordinates, pointCount, getLeaves) => {
    const r = circleScale(pointCount);
    return (
      <Marker
        className={styles.clustermarker}
        coordinates={coordinates}
        key={coordinates.toString()}
        style={{
          width: r,
          height: r
        }}
      >
        {pointCount}
        {<MapMarkerDonut leaves={getLeaves(Infinity)} r={r} />}
      </Marker>
    );
  };

  handleSetCenter = (center, zoom) => this.setState({ center, zoom });

  flyToRegion = center => {
    this.setState({
      zoom: [12],
      center
    });
  };

  zoomOut = () => {
    this.setState({
      zoom: [10]
    });
  };

  render() {
    const { placesInTime, selectedPlace, overPlace, t } = this.props;
    const { center, zoom, placePanelOpen } = this.state;
    const placesOk = placesInTime.filter(x => !!x.coordinates);

    return (
      <div
        className={styles.mapcontainer}
        ref={node => (this.mapContainer = node)}
      >
        <Map
          style={`mapbox://styles/${mapBoxStyle}`}
          center={center}
          dragRotate={false}
          keyboard={false}
          zoom={zoom}
          containerStyle={{
            height: "100%",
            width: "100%"
          }}
          animationOptions={{ offset: { x: 190, y: 0 } }}
        >
          <ZoomControl position="bottom-right" className={styles.zoomcontrol} />
          <Cluster
            maxZoom={16}
            ClusterMarkerFactory={this.clusterMarker}
            radius={60}
            zoomOnClick={true}
            zoomOnClickPadding={150}
          >
            {placesOk.map(place => {
              return (
                <Marker
                  key={`${place.id}-${place.open}`}
                  className={styles.marker}
                  coordinates={place.coordinates}
                  anchor={"center"}
                  onClick={() => {
                    this.props.setSelectedPlace(place);
                    this.openPlacePanel();
                  }}
                  onMouseEnter={() => {
                    this.props.setOverPlace(place);
                  }}
                  onMouseLeave={() => {
                    this.props.clearOverPlace();
                  }}
                >
                  <span
                    className={classNames(`icon-${place.data.place_type}`, {
                      [styles["closed"]]: !place.open
                    })}
                  />
                </Marker>
              );
            })}
          </Cluster>

          {overPlace && (
            <Popup
              coordinates={overPlace.coordinates}
              anchor="bottom"
              offset={[0, -12]}
              key={overPlace.id}
            >
              <MapTooltip
                doc={overPlace}
                className="clearfix"
                snapshot={get(
                  overPlace,
                  "documents[0].data.resolutions.medium.url"
                )}
                title={overPlace.data.title}
                placeType={t(overPlace.data.place_type)}
              />
            </Popup>
          )}
        </Map>

        <div className={styles.resetZoom} onClick={() => this.zoomOut()}>
          <i className="material-icons">center_focus_strong</i>
        </div>

        <div
          className={classNames(styles.regions, {
            [styles.hidden]: placePanelOpen
          })}
        >
          <RegionSelector
            label={t("luxembourg")}
            center={LUXEMBOURG_CENTER}
            onClick={() => this.flyToRegion(LUXEMBOURG_CENTER)}
          />
          <RegionSelector
            label={t("germany")}
            center={GERMANY_CENTER}
            onClick={() => this.flyToRegion(GERMANY_CENTER)}
          />
          <RegionSelector
            label={t("switzerland")}
            center={SWITZERLAND_CENTER}
            onClick={() => this.flyToRegion(SWITZERLAND_CENTER)}
          />
          <RegionSelector
            label={t("italy")}
            center={ITALY_CENTER}
            onClick={() => this.flyToRegion(ITALY_CENTER)}
          />
          <RegionSelector
            label={t("hong kong")}
            center={HONG_KONG_CENTER}
            onClick={() => this.flyToRegion(HONG_KONG_CENTER)}
          />
          <RegionSelector
            label={t("mexico")}
            center={MEXICO_CENTER}
            onClick={() => this.flyToRegion(MEXICO_CENTER)}
          />
        </div>

        {/* selected place panel */}
        <div
          className={classNames(styles.overlay, {
            [`${styles.open}`]: placePanelOpen
          })}
        >
          <MapDetail
            selectedPlace={selectedPlace}
            open={placePanelOpen}
            closePlacePanel={this.closePlacePanel}
          />
        </div>
      </div>
    );
  }
}

MapDesktop = connect(
  state => ({
    placesInTime: getPlacesInDate(state),
    placeTypesCount: getPlaceTypesCount(state),
    overPlace: getMapOverPlace(state),
    selectedPlace: getMapSelectedPlace(state),
    currentDate: getMapTimelineCurrentDate(state),
    currentDateRaw: state.map.currentDate,
    extent: getPlacesExtent(state)
  }),
  {
    setSelectedPlace,
    clearSelectedPlace,
    setOverPlace,
    clearOverPlace,
    setDateTimelineMap
  }
)(localize()(MapDesktop));

export default MapDesktop;
