import React from "react";
import { withBackground } from "./utils";
import Background from "../../components/Background";
import classNames from "classnames";
import { TextContent, MapContent } from "./BaseModules";
import get from "lodash/get";
import ReactMapboxGl from "react-mapbox-gl";
import { accessToken } from "../../pages/Map/consts";
import find from "lodash/find";
import { LngLatBounds } from "mapbox-gl";
import { mapPlace } from "../../state/selectors";
import styles from "./TextMapModule.module.scss";

const MapGL = ReactMapboxGl({
  accessToken,
  maxZoom: 15,
  minZoom: 6,
  scrollZoom: false,
  logoPosition: "top-left"
});

class TextMapModule extends React.Component {
  render() {
    const { module, documents, backgroundStyles, mobile } = this.props;
    const layout = get(module, "layout", "text-object");

    const objectIds = get(module.map, "objects", []).map(x => x.id);
    const places = objectIds
      .map(item => find(documents, x => x.document_id === item))
      .filter(x => x !== undefined)
      .map(x => mapPlace(x));

    const bounds = new LngLatBounds();

    places.forEach(function(place) {
      if (place.coordinates) {
        bounds.extend(place.coordinates);
      }
    });

    const fitBounds = bounds.toArray();

    return (
      <div className={styles.textMapModuleContainer}>
        {backgroundStyles.image && <Background {...backgroundStyles} />}
        <div className="container">
          <div
            className={classNames("row align-items-center", {
              "flex-row-reverse": layout === "map-text"
            })}
          >
            <div className="col-12 col-md-6">
              <TextContent textConfig={module.text} />
            </div>
            <div className="col-12 col-md-6">
              <MapContent
                module={module.map}
                places={places}
                Map={MapGL}
                fitBounds={fitBounds}
                mobile={mobile}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (TextMapModule = withBackground(TextMapModule));
