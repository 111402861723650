import React from "react";
import { withDocument } from "./utils";
import get from "lodash/get";
import find from "lodash/find";
import classNames from "classnames";
import Markdown from "markdown-to-jsx";
import { localize } from "../../localize";
import { Popup, Marker, ZoomControl, Cluster } from "react-mapbox-gl";
import { MapContext } from "react-mapbox-gl/lib-esm/context";
import MultiTouch from "mapbox-gl-multitouch";
import { scaleLinear } from "d3-scale";
import { mapBoxStyle } from "../../pages/Map/consts";
import MapTooltip from "../../pages/Map/MapTooltip";
import MapMarkerDonut from "../../pages/Map/MapMarkerDonut";
import { fixMediaCrossOrigin } from "../../utils";
import styles from "./BaseModules.module.scss";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import ReactWaves from "@dschoon/react-waves";

const OpenImageButton = ({ link }) => {
  return (
    <div className={styles.openImageButton}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <i className="material-icons">open_in_new</i>
      </a>
    </div>
  );
};

export class TextContent extends React.Component {
  render() {
    const { textConfig, isTextModule } = this.props;
    const textAlign = get(textConfig, "position", "left");
    const blockquote = textConfig.content.match(/(&gt;|>)(.*)/) ? true : false; // TODO: it works just if text starts with quote

    return (
      <div
        className={styles.textContent}
        style={{
          color: textConfig.color,
          textAlign
        }}
      >
        {blockquote ? (
          <Markdown
            className={styles.textQuotation}
            children={textConfig.content}
            options={{
              forceBlock: true
            }}
          />
        ) : (
          <div className="row">
            <div
              className={classNames("col-12", {
                "col-md-8 offset-md-2": isTextModule
              })}
            >
              <Markdown
                children={textConfig.content}
                options={{
                  forceBlock: true
                }}
              />
            </div>
          </div>
        )}
        {/*<Markdown options={{ forceBlock: true }}>{textConfig.content}</Markdown>*/}
      </div>
    );
  }
}

let ObjectContentVideo = ({ objectConfig, document }) => {
  return (
    <div className={styles["player-wrapper"]}>
      <ReactPlayer
        url={document.url}
        config={{
          youtube: {
            playerVars: { showinfo: 0, rel: 0, controls: 1, color: "white" }
          }
        }}
        width="100%"
        height="100%"
        className={styles["react-player"]}
      />
    </div>
  );
};

let ObjectContentImage = ({ objectConfig, document, goBig }) => {
  const imageUrl = get(document, "data.resolutions.medium.url", "");

  return (
    <React.Fragment>
      <img
        alt={objectConfig.caption ? objectConfig.caption : "BGL image"}
        src={imageUrl}
        className={classNames(styles.objectContentImage, {
          [styles.goBig]: goBig
        })}
      />
      <OpenImageButton link={document ? document.attachment : ""} />

      {/*   <div className={styles["selected-image-info"]}>
        {document.data.title && (
          <p className={styles["selected-image-title"]}>
            {document.data.title}
          </p>
        )}
        {document.data.provenance && (
          <p className={`${styles["selected-image-provenance"]} text-break`}>
            {document.data.provenance}
          </p>
        )}
      </div>*/}
    </React.Fragment>
  );
};

class AudioPlayer extends React.Component {
  state = {
    playing: false,
    volume: 1
  };

  handleVolumeChange = e => {
    this.setState({ volume: e.target.value });
  };

  render() {
    const { audioFile, title } = this.props;
    return (
      <div className={styles.audioContainer}>
        {/* play button */}
        <div className="d-flex align-items-center p-2 bg-white">
          <div
            onClick={() => {
              this.setState({ playing: !this.state.playing });
            }}
            className={styles.audioControlButton}
          >
            <i className="material-icons">
              {!this.state.playing ? "play_arrow" : "pause"}
            </i>
          </div>

          {/* volume control */}
          <div
            onClick={() => {
              this.setState({ volume: !this.state.volume });
            }}
            className={styles.audioControlButton}
          >
            <i className="material-icons">
              {this.state.volume ? "volume_off" : "volume_up"}
            </i>
          </div>
          <div className={`${styles.audioTitle} text-truncate`}>{title}</div>
        </div>

        {/* wave */}
        <ReactWaves
          audioFile={audioFile}
          className={styles.objectContentAudio}
          options={{
            fillParent: true,
            barHeight: 2,
            barWidth: 3,
            cursorWidth: 10,
            cursorColor: "rgba(0,0,0,0.2)",
            hideScrollbar: true,
            progressColor: "#00041b",
            responsive: true,
            waveColor: "#D1D6DA"
          }}
          volume={this.state.volume}
          zoom={1}
          playing={this.state.playing}
        />
      </div>
    );
  }
}

let ObjectContentAudio = ({ objectConfig, document }) => {
  return (
    <AudioPlayer
      audioFile={fixMediaCrossOrigin(document.src)}
      title={document.data.title}
    />
  );
};

let ObjectContent = ({ objectConfig, document, goBig }) => {
  return (
    <div className={styles.objectContainerWrapper}>
      <div
        className={classNames(styles.objectContainer, {
          [styles.videoMarginResponsive]:
            objectConfig.type === "video" ||
            objectConfig.type === "audio" ||
            goBig
        })}
      >
        <div className={styles.objectContent}>
          {(objectConfig.type === "image" || objectConfig.type === "pdf") && (
            <ObjectContentImage
              objectConfig={objectConfig}
              document={document}
              goBig={goBig}
            />
          )}
          {objectConfig.type === "video" && (
            <ObjectContentVideo
              objectConfig={objectConfig}
              document={document}
            />
          )}
          {objectConfig.type === "audio" && (
            <ObjectContentAudio
              objectConfig={objectConfig}
              document={document}
            />
          )}
        </div>
        {objectConfig.caption && (
          <div className={styles.captionWrapper}>
            <div className={styles.caption}>
              <div className={styles.captionText}>
                <p>{objectConfig.caption}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ObjectContent = withDocument(ObjectContent, "objectConfig.id");
export { ObjectContent };

const SlickArrow = props => {
  const { onClick, type, className } = props;
  const disabled = className.match(/slick-disabled/) ? true : false;
  return (
    <div
      className={classNames(styles[`${type}-arrow`], {
        [styles[`arrow-disabled`]]: disabled
      })}
    >
      <i className="material-icons" onClick={onClick}>{`arrow_${type}`}</i>
    </div>
  );
};

class GalleryContent extends React.Component {
  render() {
    const { galleryConfig, documents, settings, mobile, width } = this.props;
    const objectIds = get(galleryConfig, "objects", []).map(x => x.id);
    const imageObjects = objectIds
      .map(item => find(documents, x => x.document_id === item))
      .filter(x => x !== undefined);

    const SliderSettings = {
      ...settings,
      nextArrow: <SlickArrow type={"forward"} />,
      prevArrow: <SlickArrow type={"back"} />
    };

    const desktopHeight = "70vh";
    const mobileHeight = "350px";

    const contStyle = {
      height: mobile
        ? galleryConfig.caption
          ? `calc(${mobileHeight} + 45px)`
          : mobileHeight
        : galleryConfig.caption
        ? `calc(${desktopHeight} + 45px)`
        : desktopHeight,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    };

    return (
      <div style={settings.adaptiveHeight ? contStyle : {}}>
        <Slider {...SliderSettings}>
          {imageObjects.filter(imageObject => typeof imageObject.data.resolutions !== "undefined").map(imageObject => (
            <div key={imageObject.id}>
              {settings.variableWidth ? (
                <div
                  style={{
                    width: `calc((${desktopHeight} * ${
                      imageObject.data.resolutions.original.width
                    }) / ${imageObject.data.resolutions.original.height})`,
                    height: desktopHeight,
                    marginLeft: "35px"
                  }}
                  className={styles.slickImageContainer}
                >
                  <img
                    alt={imageObject.data.title ? imageObject.data.title : ""}
                    src={imageObject.data.resolutions.medium.url}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain"
                    }}
                  />
                  <OpenImageButton link={imageObject.attachment} />
                  <div className={styles["selected-image-info"]}>
                    {imageObject.data.title && (
                      <p className={styles["selected-image-title"]}>
                        {imageObject.data.title}
                      </p>
                    )}
                    {imageObject.data.provenance && (
                      <p
                        className={`${
                          styles["selected-image-provenance"]
                        } text-break`}
                      >
                        {imageObject.data.provenance}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.slickImageContainer}>
                  <img
                    alt="slider"
                    src={imageObject.data.resolutions.medium.url}
                    style={{
                      maxHeight: mobile ? mobileHeight : desktopHeight,
                      height:
                        (width * imageObject.data.resolutions.medium.height) /
                        imageObject.data.resolutions.medium.width,
                      width: "100%",
                      objectFit: "contain"
                    }}
                  />
                  <OpenImageButton link={imageObject.attachment} />
                  <div className={styles["selected-image-info"]}>
                    {imageObject.data.title && (
                      <p className={styles["selected-image-title"]}>
                        {imageObject.data.title}
                      </p>
                    )}
                    {imageObject.data.provenance && (
                      <p
                        className={`${
                          styles["selected-image-provenance"]
                        } text-break`}
                      >
                        {imageObject.data.provenance}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </Slider>
        {galleryConfig.caption && (
          <div className={styles.captionWrapper}>
            <div className={styles.caption}>
              <div className={styles.captionText}>
                <p>{galleryConfig.caption}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { GalleryContent };

class MapContent extends React.Component {
  state = {
    overPlace: null
  };

  setOverPlace = overPlace => this.setState({ overPlace });
  clearOverPlace = () => this.setState({ overPlace: null });
  // TODO: add close icond on popup

  clusterMarker = (coordinates, pointCount, getLeaves) => {
    const circleScale = scaleLinear()
      .range([30, 100])
      .domain([1, 150]);
    const r = circleScale(pointCount);
    return (
      <Marker
        className={styles.clustermarker}
        coordinates={coordinates}
        key={coordinates.toString()}
        style={{
          width: r,
          height: r
        }}
      >
        {pointCount}
        {<MapMarkerDonut leaves={getLeaves(Infinity)} r={r} />}
      </Marker>
    );
  };

  render() {
    const { module, places, Map, fitBounds, t, mobile } = this.props;

    const { overPlace } = this.state;
    const placesOk = places ? places.filter(x => !!x.data.coordinates) : [];

    return (
      <React.Fragment>
        <div
          className={styles.mapcontainer}
          ref={node => (this.mapContainer = node)}
        >
          {places && (
            <Map
              style={`mapbox://styles/${mapBoxStyle}`}
              fitBounds={fitBounds}
              fitBoundsOptions={{ padding: 100 }}
              dragRotate={false}
              keyboard={false}
              containerStyle={{
                height: "100%",
                width: "100%"
              }}
            >
              {mobile && (
                <MapContext.Consumer>
                  {map => {
                    map.addControl(new MultiTouch());
                  }}
                </MapContext.Consumer>
              )}
              <ZoomControl
                position="top-right"
                className={styles.zoomcontrol}
              />

              <Cluster
                maxZoom={16}
                ClusterMarkerFactory={this.clusterMarker}
                radius={60}
                zoomOnClick={true}
                zoomOnClickPadding={100}
              >
                {placesOk.map(place => {
                  return (
                    <Marker
                      key={`${place.id}`}
                      className={styles.marker}
                      coordinates={place.coordinates}
                      anchor={"center"}
                      onClick={() => {
                        this.setOverPlace(place);
                      }}
                    >
                      <span className={`icon-${place.data.place_type}`} />
                    </Marker>
                  );
                })}
              </Cluster>

              {overPlace && (
                <Popup
                  coordinates={overPlace.coordinates}
                  anchor="bottom"
                  offset={[0, -12]}
                  key={overPlace.id}
                >
                  <MapTooltip
                    doc={overPlace}
                    className="clearfix"
                    snapshot={get(
                      overPlace,
                      "documents[0].data.resolutions.medium.url"
                    )}
                    title={overPlace.data.title}
                    placeType={t(overPlace.data.place_type)}
                    closeIcon
                    clearOverPlace={this.clearOverPlace}
                  />
                </Popup>
              )}
            </Map>
          )}
        </div>
        {module.caption && (
          <div className={styles.captionWrapper}>
            <div className={styles.caption}>
              <div className={styles.captionText}>
                <p>{module.caption}</p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

MapContent = localize()(MapContent);
export { MapContent };
