import React from "react";
import { arc, pie } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { entries } from "d3-collection";
import styles from "./MapMarkerDonut.module.scss";

class MapMarkerDonut extends React.PureComponent {
  render() {
    const { leaves, r } = this.props;

    const points = {
      open: 0,
      close: 0
    };

    leaves.forEach(d => {
      const status = d.key.split("-").slice(-1)[0];
      if (status === "false") {
        points.close++;
      } else {
        points.open++;
      }
    });

    const myScale = scaleLinear()
      .range([0, 100])
      .nice()
      .domain([0, points.open + points.close]);
    const myArc = arc()
      .innerRadius(r / 2 - 3)
      .outerRadius(r / 2);
    const myPie = pie()
      .value(function(d) {
        return myScale(d.value);
      })
      .sort(null);

    const mydata = myPie(entries(points));
    return (
      <svg width={r} height={r}>
        <g transform={`translate(${r / 2},${r / 2})`}>
          {mydata &&
            mydata.map(d => {
              return (
                <g key={d.data.key}>
                  <path d={myArc(d)} className={styles[d.data.key]} />
                </g>
              );
            })}
        </g>
      </svg>
    );
  }
}

export default MapMarkerDonut;
