import React from "react";
import { connect } from "react-redux";
import { setDateTimelineMap } from "../../state/actions";
import {
  getMapTimelineCurrentDate,
  getPlacesExtent
} from "../../state/selectors";
import { timeYear } from "d3-time";
import { scaleTime } from "d3-scale";
import { defaultMemoize } from "reselect";
import moment from "moment";
import { Spring } from "react-spring/renderprops";
import { DraggableCore } from "react-draggable";
import { localize } from "../../localize";
import styles from "./MapNavigationDesktop.module.scss";

// #TODO: MOVE IN PARENT COMPONENT OR TO UTILS
const getScale = defaultMemoize((extent, width, padding) => {
  const scale = scaleTime()
    .domain([
      moment(extent[0])
        .startOf("year")
        .toDate(),
      moment(extent[1])
        .endOf("year")
        .toDate()
    ])
    .range([0, width - padding])
    .nice(timeYear);
  return scale;
});

class MapNavigationDesktop extends React.Component {
  state = {
    width: 0
  };

  measure = () => {
    const domRect = this.ganttRef.current.getBoundingClientRect();
    const { width } = domRect;
    this.setState({ width });
  };

  componentDidMount = () => {
    this.measure();
    window.addEventListener("resize", this.measure);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.measure);
  }

  onDrag = (e, data) => {
    const { extent, currentDate, setDateTimelineMap } = this.props;
    const { width } = this.state;
    const scale = getScale(extent, width, 25 * 2);
    const x = scale(currentDate);
    const newX = x + data.deltaX;
    const newDate = scale.invert(newX);
    if (newDate < extent[0] || newDate > extent[1]) {
      return;
    }
    setDateTimelineMap(newDate);
  };

  handleClick = e => {
    e.persist();
    const { extent, setDateTimelineMap } = this.props;
    const { width } = this.state;
    const rect = this.svgNode.getBoundingClientRect();
    const x = e.clientX - rect.x - 25;
    const scale = getScale(extent, width, 25 * 2);
    const newDate = scale.invert(x);
    if (newDate < extent[0] || newDate > extent[1]) {
      return;
    }
    setDateTimelineMap(newDate);
  };

  ganttRef = React.createRef();

  render() {
    const { extent, currentDate } = this.props;
    const { width } = this.state;

    const padding = 25;
    const scale = getScale(extent, width, padding * 2);
    let bottomTicks = scale.ticks();
    bottomTicks.push(moment({ years: 2020 }).toDate());
    const x = scale(currentDate);

    return (
      <div className={styles.navigationcontainer}>
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className={`${styles["legend-container"]} col-3 h-100`}>
              <div
                className={`${
                  styles["legend"]
                } d-flex justify-content-center align-items-center h-100`}
              >
                {moment(currentDate).format("YYYY")}
              </div>
            </div>
            <div className="col-9 p-0" ref={this.ganttRef}>
              {width && (
                <Spring to={{ x: x }} config={{ tension: 0, friction: 4 }}>
                  {springProps => (
                    <DraggableCore handle=".handle" onDrag={this.onDrag}>
                      <svg
                        width={width}
                        height={30}
                        ref={node => (this.svgNode = node)}
                      >
                        <g transform={`translate(${padding},0)`}>
                          <rect
                            ref={node => (this.svgNode = node)}
                            onClick={this.handleClick}
                            width={width - padding * 2}
                            height={30}
                            x={0}
                          />
                          {/* ticks */}
                          <g>
                            {bottomTicks.map((tick, i) => {
                              const transformedTick =
                                tick.getFullYear() === 1920
                                  ? moment({
                                      years: tick.getFullYear() - 1
                                    }).toDate()
                                  : tick;
                              return (
                                <g key={i}>
                                  <text
                                    className={styles.tick}
                                    x={scale(transformedTick)}
                                    y={20}
                                    dy={0}
                                  >
                                    {transformedTick.getFullYear()}
                                  </text>
                                </g>
                              );
                            })}
                          </g>

                          {/* cursor */}
                          <g
                            transform={`translate(${springProps.x},0)`}
                            className="handle pointer"
                          >
                            <rect
                              x={-2}
                              width={3}
                              height={30}
                              className={styles["cursor-bar"]}
                            />
                          </g>
                        </g>
                      </svg>
                    </DraggableCore>
                  )}
                </Spring>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MapNavigationDesktop = connect(
  state => ({
    currentDate: getMapTimelineCurrentDate(state),
    currentDateRaw: state.map.currentDate,
    extent: getPlacesExtent(state)
  }),
  {
    setDateTimelineMap
  }
)(localize()(MapNavigationDesktop));

export default MapNavigationDesktop;
