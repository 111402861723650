import React from "react";
import { connect } from "react-redux";
import { localize } from "../../localize";
import {
  loadPlaces,
  unloadPlaces,
  unloadMap,
  setLoaderVisible
} from "../../state/actions";
import { getPlaces, getStories } from "../../state/selectors";
import MapDesktop from "./MapDesktop";
import MapMobile from "./MapMobile";
import MapGantt from "./MapGantt";
import MapNavigationDesktop from "./MapNavigationDesktop";
import MapNavigationMobile from "./MapNavigationMobile";
import Head from "../../components/Head";
import Loader from "../../components/Loader";

class Map extends React.Component {
  componentDidMount() {
    this.props.setLoaderVisible(true);
    this.props.loadPlaces({ detailed: true });
  }

  componentWillUnmount() {
    this.props.unloadPlaces();
    this.props.unloadMap();
    this.props.setLoaderVisible(false);
  }

  componentDidUpdate(oldProps) {
    if (this.props.places && !oldProps.places) {
      this.props.setLoaderVisible(false);
    }
  }

  render() {
    const { mobile, places, stories } = this.props;
    const story = stories ? stories.filter(d => d.slug === "map") : [];
    const title = story.length ? story[0].data.title : "";
    const description = story.length ? story[0].data.abstract : "";
    return (
      <React.Fragment>
        {stories && <Head title={title} description={description} />}
        <div className="h-100 d-flex flex-column postion-relative">
          {!mobile && (
            <React.Fragment>
              {places && <MapDesktop />}
              {places && <MapGantt />}
              {places && <MapNavigationDesktop />}
            </React.Fragment>
          )}

          {mobile && (
            <React.Fragment>
              {places && <MapMobile />}
              {places && <MapNavigationMobile />}
              <Loader />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

Map.defaultProps = {
  mobile: false
};

Map = connect(
  state => ({
    places: getPlaces(state),
    stories: getStories(state)
  }),
  {
    loadPlaces,
    unloadPlaces,
    unloadMap,
    setLoaderVisible
  }
)(localize()(Map));

export default Map;
