import React from "react";
import { withBackground } from "./utils";
import { GalleryContent } from "./BaseModules";
import Background from "../../components/Background";
import styles from "./GalleryModule.module.scss";

class GalleryModule extends React.Component {
  state = { width: 0 };

  galleryContainer = React.createRef();

  componentDidMount() {
    const styles = window.getComputedStyle(this.galleryContainer.current);
    const padding =
      parseInt(styles.paddingLeft) + parseInt(styles.paddingRight);
    this.setState({
      width: this.galleryContainer.current.clientWidth - padding
    });
  }

  render() {
    const { module, documents, backgroundStyles, mobile } = this.props;
    const { width } = this.state;
    const settings = {
      className: "slider variable-width",
      infinite: true,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true
    };

    const settingsMobile = {
      className: "slider",
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      fade: true
    };

    return (
      <div className={styles.galleryModuleContainer}>
        {backgroundStyles.image && <Background {...backgroundStyles} />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-md-0" ref={this.galleryContainer}>
              <GalleryContent
                galleryConfig={module}
                settings={mobile ? settingsMobile : settings}
                documents={documents}
                mobile={mobile}
                width={width}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (GalleryModule = withBackground(GalleryModule));
