import React from "react";
import styles from './RegionSelector.module.scss'
import { accessToken, mapBoxStyle } from './consts'


const RegionSelector = ({ center, zoom, label, onClick }) => {
  return (
    <div className={styles.regionSelector}>
      <div
        className={styles.region}
        onClick={onClick}
        style={{
          backgroundImage: `url(https://api.mapbox.com/styles/v1/${mapBoxStyle}/static/${
            center[0]
          },${
            center[1]
          },${zoom},0,0/150x100@2x?access_token=${accessToken}&attribution=false&logo=false)`
        }}
      >
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  );
};
RegionSelector.defaultProps = {
  zoom: 4
};


export default RegionSelector