import React from "react";
import classNames from "classnames";
import styles from "./MapTooltip.module.scss";

const MapToolTip = ({
  placeType,
  snapshot,
  title,
  closeIcon,
  clearOverPlace
}) => {
  return (
    <div
      className={classNames(styles.tooltip, {
        [styles.noimage]: !snapshot
      })}
      style={{
        backgroundImage: snapshot
          ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.9)),url(${snapshot})`
          : undefined
      }}
    >
      <div className={styles.description}>
        <span>{placeType}</span>
        {closeIcon && (
          <i onClick={() => clearOverPlace()} className="material-icons">
            close
          </i>
        )}
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default MapToolTip;
