import {
  SET_LOADER_VISIBLE,
} from '../actions'

export default (prevState = false, { type, payload }) => {
  switch (type) {
    case SET_LOADER_VISIBLE:
      return payload
    default:
      return prevState
  }
}
