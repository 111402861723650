import React from "react";
import { withBackground } from "./utils";
import Background from "../../components/Background";
import { TextContent } from "./BaseModules";
import styles from "./TextModule.module.scss";

class TextModule extends React.Component {
  render() {
    const { module, backgroundStyles } = this.props;
    return (
      <div className={styles.textModuleContainer}>
        {backgroundStyles.image && <Background {...backgroundStyles} />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <TextContent textConfig={module.text} isTextModule={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (TextModule = withBackground(TextModule));
