import { createSelector } from 'reselect'
import { getSelectedLangCode } from './lang'
import { translateDoc } from './common'

export const getThemes = createSelector(
  state => state.themes.data,
  getSelectedLangCode,
  (themes, langCode) => {
    if (themes === null) {
      return null
    }
    return translateDoc(themes, langCode)
  }
)
