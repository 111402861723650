import React from "react";
import classNames from "classnames";
import { localize } from "../../localize";
import get from "lodash/get";
import moment from "moment";
import OpenImageButton from "../../components/OpenImageButton";
import styles from "./TimelineDetail.module.scss";

class TimelineDetail extends React.PureComponent {
  render() {
    const { selectedEvent, closeEventPanel, t, open } = this.props;
    return (
      <div
        className={classNames(styles.eventPanel, {
          [`${styles.open}`]: open
        })}
      >
        {selectedEvent && (
          <div className={styles.selectedEventContent}>
            {/* category and close control */}
            <div
              className={`${
                styles["detail-top"]
              } d-flex justify-content-between align-items-center`}
            >
              <h6 className={styles["detail-category"]}>
                {t(selectedEvent.data.category)}
              </h6>
              <i className="material-icons" onClick={closeEventPanel}>
                close
              </i>
            </div>
            {/* image if any */}
            {get(selectedEvent, "documents[0].data.resolutions.medium.url") && (
              <div className={styles["selected-image-container"]}>
                <img
                  className={styles.selectedEventImage}
                  alt={selectedEvent.title}
                  src={selectedEvent.documents[0].data.resolutions.medium.url}
                />
                <OpenImageButton link={selectedEvent.documents[0].attachment} />
                <div className={styles["selected-image-info"]}>
                  {selectedEvent.documents[0].data.title && (
                    <p className={styles["selected-image-title"]}>
                      {selectedEvent.documents[0].data.title}
                    </p>
                  )}
                  {selectedEvent.documents[0].data.provenance && (
                    <p
                      className={`${
                        styles["selected-image-provenance"]
                      } text-break`}
                    >
                      {selectedEvent.documents[0].data.provenance}
                    </p>
                  )}
                </div>
              </div>
            )}
            {/* dates */}
            <div className="d-flex justify-content-between">
              <span className={styles["detail-date"]}>
                {moment(selectedEvent.startDate).format("DD-MM-YYYY")}
              </span>
              {moment(selectedEvent.endDate).format("YYYY-MM-DD") !==
                moment(selectedEvent.startDate).format("YYYY-MM-DD") && (
                <span className={styles["detail-date"]}>
                  {selectedEvent.endDate &&
                    moment(selectedEvent.endDate).format("DD-MM-YYYY")}
                </span>
              )}
            </div>
            {/* title and description */}
            <h5 className={styles["detail-title"]}>
              {selectedEvent.data.title}
            </h5>
            <p>{selectedEvent.data.description}</p>
          </div>
        )}
      </div>
    );
  }
}

export default localize()(TimelineDetail);
