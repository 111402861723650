import React from "react";
import { withRouter, Link, matchPath } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import { Trail } from "react-spring/renderprops";
import { localize } from "../../localize";
import { getStories } from "../../state/selectors";
import { loadStories, unloadStories } from "../../state/actions";
import Cookies from "../Cookies";
import styles from "./AppContainerMobile.module.scss";

class Bar extends React.Component {
  render() {
    const { story, barOpen, location, toggleBarOpen, t, style } = this.props;
    return (
      <React.Fragment>
        <div
          className={classNames(
            `${styles["menu-bar"]} ${styles[`${story.slug}-bg`]}`,
            {
              [`${styles["open"]}`]: barOpen[story.slug],
              [`${styles["pages"]}`]: matchPath(location.pathname, {
                path: [`/${story.slug}`]
              })
            }
          )}
          style={style}
        >
          <div className={styles["menu-bar-title"]}>
            <div className={styles["title-container"]}>
              <p className="text-uppercase">{story.data.title}</p>
            </div>
            <div className={styles["expand-container"]}>
              <i className="material-icons" onClick={toggleBarOpen}>
                add
              </i>
            </div>
          </div>

          <div className={styles["menu-bar-desc"]}>
            <div className={styles["desc-container"]}>
              <p>{story.data.abstract}</p>
            </div>
            <div className={styles["desc-link-container"]}>
              <Link
                className={styles["desc-link"]}
                to={`/${story.slug}`}
                onClick={toggleBarOpen}
              >
                <span>{t("home_explore")}</span>
                <span className="d-flex">
                  <i className="material-icons">arrow_forward</i>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles["menu-page-content"], {
            [`${styles["open"]}`]: matchPath(location.pathname, {
              path: [`/${story.slug}`]
            })
          })}
        />
      </React.Fragment>
    );
  }
}

class AppContainerMobile extends React.PureComponent {
  state = {
    barOpen: {},
    menuOpen: false
  };

  componentDidMount() {
    this.props.loadStories();
  }

  componentWillUnmount() {
    this.props.unloadStories();
  }

  toggleMenuOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  toggleBarOpen = name => () => {
    const { barOpen } = this.state;
    const newValue = !barOpen[name];
    this.setState({ barOpen: { [name]: newValue } });
  };

  render() {
    const { barOpen, menuOpen } = this.state;
    const { location, t, stories } = this.props;
    const menuMobile = [
      { url: "/", label: "home" },
      { url: "/about", label: "about" }
    ];

    return (
      <div className={`${styles["AppContainerMobile"]} h-100 text-white`}>
        <div
          className={classNames(styles["top-bar"], {
            [styles["map-bg"]]:
              !menuOpen && matchPath(location.pathname, { path: ["/map"] }),
            [styles["timeline-bg"]]:
              !menuOpen &&
              matchPath(location.pathname, { path: ["/timeline"] }),
            [styles["themes-bg"]]:
              !menuOpen && matchPath(location.pathname, { path: ["/themes"] })
          })}
        >
          {matchPath(location.pathname, { path: ["/timeline"] }) && (
            <div className={styles.title}>
              {stories &&
                stories.filter(d => d.slug === "timeline")[0].data.title}
            </div>
          )}

          {matchPath(location.pathname, { path: ["/map"] }) && (
            <div className={styles.title}>
              {stories && stories.filter(d => d.slug === "map")[0].data.title}
            </div>
          )}

          {matchPath(location.pathname, { path: ["/themes"] }) && (
            <div className={styles.title}>
              {matchPath(location.pathname, { path: `/themes/:slug` }) && (
                <Link to="/themes" className="">
                  <i className="d-flex material-icons mr-2">arrow_back</i>
                </Link>
              )}
              {stories &&
                stories.filter(d => d.slug === "themes")[0].data.title}
            </div>
          )}

          <div className={styles["toggle-menu"]} onClick={this.toggleMenuOpen}>
            <i className="material-icons">{menuOpen ? "close" : "menu"}</i>
          </div>
        </div>

        {/* mobile menu */}
        <div
          className={classNames(styles["menu-mobile"], {
            [styles["open"]]: menuOpen
          })}
        >
          {menuMobile.map(menu => {
            return (
              <div
                key={menu.label}
                className={`${styles["menu-mobile-link"]} ${
                  styles[menu.label + "-bg"]
                }`}
              >
                <Link
                  to={menu.url}
                  onClick={this.toggleMenuOpen}
                  className={styles["desc-link"]}
                >
                  <span>{t(`menu_${menu.label}`)}</span>
                  <span className="d-flex">
                    <i className="material-icons">arrow_forward</i>
                  </span>
                </Link>
              </div>
            );
          })}

          {stories &&
            stories.map(story => {
              return (
                <div
                  key={story.slug}
                  className={`${styles["menu-mobile-link"]} ${
                    styles[story.slug + "-bg"]
                  }`}
                >
                  <Link
                    to={`/${story.slug}`}
                    onClick={this.toggleMenuOpen}
                    className={styles["desc-link"]}
                  >
                    <span>{story.data.title}</span>
                    <span className="d-flex">
                      <i className="material-icons">arrow_forward</i>
                    </span>
                  </Link>
                </div>
              );
            })}
        </div>

        {/* main content */}
        <div
          className={classNames(styles["content"], {
            [styles["p-home"]]: matchPath(location.pathname, {
              path: "/",
              exact: true
            }),
            [styles["p-timeline"]]: matchPath(location.pathname, {
              path: "/timeline"
            }),
            [styles["p-map"]]: matchPath(location.pathname, {
              path: "/map"
            }),
            [styles["p-themes"]]: matchPath(location.pathname, {
              path: `/themes`
            })
          })}
        >
          {this.props.children}
        </div>

        {/* bottom accordion for home and about */}
        {matchPath(location.pathname, {
          path: ["/"],
          exact: true
        }) && (
          <div
            className={classNames(styles["menu-container"], {
              [`${styles["pages"]}`]: !matchPath(location.pathname, {
                path: ["/", "/about"],
                exact: true
              })
            })}
          >
            {stories && (
              <Trail
                items={stories}
                keys={item => item.slug}
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
              >
                {item => props => (
                  <Bar
                    story={item}
                    toggleBarOpen={this.toggleBarOpen(item.slug)}
                    location={location}
                    barOpen={barOpen}
                    style={props}
                    t={t}
                  />
                )}
              </Trail>
            )}
          </div>
        )}
        <Cookies mobile/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stories: getStories(state)
});

export default localize()(
  connect(
    mapStateToProps,
    {
      loadStories,
      unloadStories
    }
  )(withRouter(AppContainerMobile))
);
