import React from "react";
import { connect } from "react-redux";
import { DraggableCore } from "react-draggable";
import { Spring } from "react-spring/renderprops";
import moment from "moment";
import get from "lodash/get";
import classNames from "classnames";
import {
  getTimelineDesktopScale,
  getTimelineCurrentDate,
  getAnnotatedEvents,
  getSelectedEvent,
  getEventsExtent,
  getTimelineYearsWithEvents,
  TOP_SCALE_PADDING
} from "../../state/selectors";
import last from "lodash/last";
import {
  setDateTimeline,
  selectEvent,
  clearSelectedEvent
} from "../../state/actions";
import { localize } from "../../localize";
import { descending } from "d3-array";
import MultiText from "../../components/MultiText";
import TimelineDetail from "./TimelineDetail";
import styles from "./TimelineEvents.module.scss";

const IMAGES_ROW_HEIGHT = 150;
//const MIN_EVENTS_WIDTH = 10;
const CATEGORY_LABEL_PADDING = 30;

const TimeGlyph = ({ startDateX, endDateX }) => {
  if (startDateX === endDateX) {
    return (
      <circle className={styles.eventPoint} cx={startDateX + 4} cy={0} r="4" />
    );
  } else {
    return [
      <line
        key="line"
        className={styles.eventLine}
        x1={startDateX}
        x2={endDateX}
      />,
      <line
        key="start"
        className={styles.eventLine}
        x1={startDateX + 1}
        x2={startDateX + 1}
        y1={-3}
        y2={3}
      />,
      <circle
        key="end"
        className={styles.eventPoint}
        cx={endDateX - 4}
        cy={0}
        r="4"
      />
    ];
  }
};

class TimelineEvent extends React.PureComponent {
  state = {
    width: 0,
    height: 0
  };

  measure = () => {
    const domRect = this.containerNode.getBoundingClientRect();
    const { width, height } = domRect;
    this.setState({ width, height });
  };

  componentDidMount() {
    this.measure();
  }

  render() {
    const {
      event,
      scale,
      DISPLACEMENTS,
      EVENT_ROW_HEIGHT,
      openEvent,
      onMouseEnter,
      onMouseOut,
      highlightEvent
    } = this.props;
    const { width, height } = this.state;
    const startDateX = scale(event.startDate);
    let endDateX = scale(event.endDate);
    // if (endDateX - startDateX < MIN_EVENTS_WIDTH) {
    //   endDateX = startDateX + MIN_EVENTS_WIDTH;
    // }

    const USABLE_HEIGHT = EVENT_ROW_HEIGHT - CATEGORY_LABEL_PADDING;

    return (
      <g
        className={classNames(styles.eventContainer, {
          [styles.faded]: highlightEvent && highlightEvent.id !== event.id
        })}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        ref={node => (this.containerNode = node)}
        transform={`translate(0, ${DISPLACEMENTS[event.data.category] +
          CATEGORY_LABEL_PADDING +
          (event.displacementIndex * USABLE_HEIGHT) / 3})`}
        onClick={() => openEvent(event)}
      >
        <rect
          x={startDateX}
          width={width}
          height={height}
          style={{ fill: "transparent" }}
        />

        <TimeGlyph startDateX={startDateX} endDateX={endDateX} />

        <text x={startDateX} className={styles.eventDate} y={16}>
          {moment(event.startDate).format("DD-MM-YYYY")}
        </text>
        <MultiText
          spacing={14}
          x={startDateX}
          y={30}
          className={styles.eventTitle}
          text={event.data.title}
          maxLine={USABLE_HEIGHT / 3 < 50 ? 1 : 2}
          maxLen={30}
        />
      </g>
    );
  }
}

class TimelineEvents extends React.Component {
  state = {
    width: 0,
    height: 0,
    highlightEvent: null,
    eventPanelOpen: false,
    eventsWithImages: []
  };

  setHighLightEvent = highlightEvent => {
    this.setState({ highlightEvent });
  };

  closeEventPanel = () => {
    this.setState({ eventPanelOpen: false });
  };

  openEventPanel = () => {
    this.setState({ eventPanelOpen: true });
  };

  measure = () => {
    const domRect = this.containerNode.getBoundingClientRect();
    const { width, height } = domRect;
    this.setState({ width, height });
  };

  componentDidMount() {
    const eventsWithImages = []
      .concat(this.props.events)
      .filter(x => get(x, "documents[0].data.resolutions.thumbnail.url"))
      .map(x => ({
        ...x,
        snapshot: x.documents[0].data.resolutions.thumbnail.url //.attachment,
      }))
      .sort((a, b) =>
        descending(
          a.documents[0].data.resolutions.original.width /
            a.documents[0].data.resolutions.original.height,
          b.documents[0].data.resolutions.original.width /
            b.documents[0].data.resolutions.original.height
        )
      );

    this.setState({ eventsWithImages });

    this.measure();
    window.addEventListener("resize", this.measure);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.measure);
    this.props.clearSelectedEvent();
  }

  // #TODO: these two methods will help if next-prev event must be implemented

  // goNext = () => {
  //   const { selectEvent, setDateTimeline, nextEvent } = this.props;
  //   setDateTimeline(nextEvent.startDate);
  //   selectEvent(nextEvent);
  // };

  // goPrev = () => {
  //   const { selectEvent, setDateTimeline, prevEvent } = this.props;
  //   setDateTimeline(prevEvent.startDate);
  //   selectEvent(prevEvent);
  // };

  onDrag = (e, data) => {
    const { scale, currentDate, setDateTimeline, extent } = this.props;
    const x = scale(currentDate);
    const newX = x - data.deltaX;
    const newDate = scale.invert(newX);
    if (newDate < extent[0] || newDate > extent[1]) {
      return;
    }
    setDateTimeline(newDate);
  };

  render() {
    const {
      events,
      scale,
      years,
      currentDate,
      selectedEvent,
      selectEvent,
      t
    } = this.props;
    const width = last(scale.range()) + window.innerWidth;
    const {
      height,
      highlightEvent,
      eventPanelOpen,
      eventsWithImages
    } = this.state;
    const x = -scale(currentDate) + TOP_SCALE_PADDING;

    // const eventsWithImages = []
    //   .concat(events)
    //   .filter(x => get(x, "documents[0].data.resolutions.thumbnail.url"))
    //   .map(x => ({
    //     ...x,
    //     snapshot: x.documents[0].data.resolutions.thumbnail.url //.attachment,
    //   }))
    //   .sort((a, b) =>
    //     descending(
    //       a.documents[0].data.resolutions.original.width /
    //         a.documents[0].data.resolutions.original.height,
    //       b.documents[0].data.resolutions.original.width /
    //         b.documents[0].data.resolutions.original.height
    //     )
    //   );

    const EVENT_ROW_HEIGHT = (height - IMAGES_ROW_HEIGHT) / 3;
    const DISPLACEMENTS = {
      "history of the bank": IMAGES_ROW_HEIGHT,
      political: IMAGES_ROW_HEIGHT + EVENT_ROW_HEIGHT,
      "socio-economic": IMAGES_ROW_HEIGHT + EVENT_ROW_HEIGHT * 2
    };

    return (
      <div
        className={styles["container"]}
        ref={node => (this.containerNode = node)}
      >
        {height && (
          <Spring to={{ x: x }} config={{ tension: 0, friction: 4 }}>
            {springProps => (
              <DraggableCore handle=".handle" onDrag={this.onDrag}>
                <div className={`${styles["container-timeline"]} handle`}>
                  <svg
                    className="h-100"
                    width={width}
                    style={{ transform: `translate(${springProps.x}px,0)` }}
                  >
                    {/* years top */}
                    <g transform={`translate(0, ${IMAGES_ROW_HEIGHT - 10})`}>
                      {years.map(year => (
                        <g key={year.year}>
                          <text className={styles.tick} x={scale(year.date)}>
                            {year.year}
                          </text>
                        </g>
                      ))}
                    </g>

                    <rect
                      transform={`translate(0, ${
                        DISPLACEMENTS["history of the bank"]
                      })`}
                      className={styles.historyRow}
                      width={width}
                      height={EVENT_ROW_HEIGHT}
                    />
                    <rect
                      transform={`translate(0, ${DISPLACEMENTS["political"]})`}
                      className={styles.politicalRow}
                      width={width}
                      height={EVENT_ROW_HEIGHT}
                    />
                    <rect
                      transform={`translate(0, ${
                        DISPLACEMENTS["socio-economic"]
                      })`}
                      className={styles.socioEconomicRow}
                      width={width}
                      height={EVENT_ROW_HEIGHT}
                    />

                    {/* events category labels */}
                    {/* (not scrolling - remove x={-springProps.x} to let them scroll) */}
                    <g
                      transform={`translate(0, ${
                        DISPLACEMENTS["history of the bank"]
                      })`}
                    >
                      <text
                        x={-springProps.x + 15}
                        dy={18}
                        className={classNames(styles.categoryLabel, {
                          [styles.faded]:
                            highlightEvent &&
                            highlightEvent.data.category !==
                              "history of the bank"
                        })}
                      >
                        {t("history of the bank")}
                      </text>
                    </g>
                    <g
                      transform={`translate(0, ${DISPLACEMENTS["political"]})`}
                    >
                      <text
                        x={-springProps.x + 15}
                        dy={18}
                        className={classNames(styles.categoryLabel, {
                          [styles.faded]:
                            highlightEvent &&
                            highlightEvent.data.category !== "political"
                        })}
                      >
                        {t("political")}
                      </text>
                    </g>
                    <g
                      transform={`translate(0, ${
                        DISPLACEMENTS["socio-economic"]
                      })`}
                    >
                      <text
                        x={-springProps.x + 15}
                        dy={18}
                        className={classNames(styles.categoryLabel, {
                          [styles.faded]:
                            highlightEvent &&
                            highlightEvent.data.category !== "socio-economic"
                        })}
                      >
                        {t("socio-economic")}
                      </text>
                    </g>

                    {/* grid top */}
                    <g transform={`translate(0, 0)`}>
                      {years.map(year => (
                        <g key={year.year}>
                          <line
                            className={styles.grid}
                            x1={scale(year.date)}
                            x2={scale(year.date)}
                            y1={0}
                            y2={height - 23}
                          />
                        </g>
                      ))}
                    </g>

                    <g>
                      {events.map(event => (
                        <TimelineEvent
                          highlightEvent={highlightEvent}
                          onMouseEnter={() => this.setHighLightEvent(event)}
                          onMouseOut={() => this.setHighLightEvent(null)}
                          openEvent={event => {
                            selectEvent(event);
                            this.openEventPanel();
                          }}
                          key={event.id}
                          event={event}
                          DISPLACEMENTS={DISPLACEMENTS}
                          EVENT_ROW_HEIGHT={EVENT_ROW_HEIGHT}
                          scale={scale}
                        />
                      ))}
                    </g>

                    {/* years bottom */}
                    <g transform={`translate(0, ${height - 5})`}>
                      {years.map(year => (
                        <g key={year.year}>
                          <text className={styles.tick} x={scale(year.date)}>
                            {year.year}
                          </text>
                        </g>
                      ))}
                    </g>
                  </svg>
                  {
                    <div
                      className={styles["container-images"]}
                      style={{
                        transform: `translate(${springProps.x}px,0)`,
                        width: width
                      }}
                    >
                      <div className={styles["container-images-utility"]}>
                        {eventsWithImages.map(event => (
                          <img
                            alt={
                              event.documents && event.documents.length > 0
                                ? event.documents[0].data.title
                                : "BGL image"
                            }
                            draggable="false"
                            style={{
                              left: `${scale(event.startDate)}px`,
                              top: `${
                                highlightEvent && highlightEvent.id === event.id
                                  ? DISPLACEMENTS[event.data.category] -
                                    12.5 -
                                    110 +
                                    CATEGORY_LABEL_PADDING +
                                    (event.displacementIndex *
                                      (EVENT_ROW_HEIGHT -
                                        CATEGORY_LABEL_PADDING)) /
                                      3
                                  : 0
                              }px`
                            }}
                            onClick={() => {
                              selectEvent(event);
                              this.openEventPanel();
                            }}
                            key={event.id}
                            src={event.snapshot}
                            className={classNames(styles.eventImage, {
                              [styles.faded]:
                                highlightEvent && highlightEvent.id !== event.id
                            })}
                          />
                        ))}
                      </div>
                    </div>
                  }
                </div>
              </DraggableCore>
            )}
          </Spring>
        )}

        {/* selected event panel */}
        <div
          className={classNames(styles.overlay, {
            [`${styles.open}`]: eventPanelOpen
          })}
        >
          <TimelineDetail
            selectedEvent={selectedEvent}
            open={eventPanelOpen}
            closeEventPanel={this.closeEventPanel}
          />

          {eventPanelOpen && (
            <div
              onClick={this.closeEventPanel}
              className={classNames(styles.backdrop, {
                [`${styles.open}`]: eventPanelOpen
              })}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    scale: getTimelineDesktopScale(state),
    events: getAnnotatedEvents(state),
    years: getTimelineYearsWithEvents(state),
    currentDate: getTimelineCurrentDate(state),
    extent: getEventsExtent(state),
    selectedEvent: getSelectedEvent(state)
  }),
  {
    setDateTimeline,
    clearSelectedEvent,
    selectEvent
  }
)(localize()(TimelineEvents));
