import React from "react";
import get from "lodash/get";
import NotImplementedModule from "./NotImplementedModule";
import TextModule from "./TextModule";
import ObjectModule from "./ObjectModule";
import TextObjectModule from "./TextObjectModule";
import TextGalleryModule from "./TextGalleryModule";
import TextMapModule from "./TextMapModule";
import MapModule from "./MapModule";
import GalleryModule from "./GalleryModule";

const Module = ({ module, documents, mobile }) => {
  const moduleType = get(module, "module");

  // fallback for not implemented modules
  let ModuleComponent = NotImplementedModule;

  if (moduleType === "text_object") {
    ModuleComponent = TextObjectModule;
  }

  if (moduleType === "text_gallery") {
    ModuleComponent = TextGalleryModule;
  }

  if (moduleType === "text_map") {
    ModuleComponent = TextMapModule;
  }

  if (moduleType === "text") {
    ModuleComponent = TextModule;
  }

  if (moduleType === "map") {
    ModuleComponent = MapModule;
  }

  if (moduleType === "gallery") {
    ModuleComponent = GalleryModule;
  }

  if (moduleType === "object") {
    ModuleComponent = ObjectModule;
  }

  return (
    <ModuleComponent module={module} documents={documents} mobile={mobile} />
  );
};

export default React.memo(Module);
